import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../core/_services/message.service';
import { ShopDataService } from '../_services/shop-data.service';
import { ShopData } from 'src/app/_models/shop-data.model';

@Component({
  selector: 'app-delivery-hours',
  templateUrl: './delivery-hours.component.html',
  styleUrls: ['./delivery-hours.component.scss']
})
export class DeliveryHoursComponent implements OnInit {
  private CHECKBOXES = ['deliveryHoursMondayClosed', 'deliveryHoursTuesdayClosed', 'deliveryHoursWednesdayClosed', 'deliveryHoursThursdayClosed', 'deliveryHoursFridayClosed', 'deliveryHoursSaturdayClosed', 'deliveryHoursSundayClosed'];
  public form: FormGroup;

  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  public shopData: ShopData;

  private disableChangeEvents = false;

  constructor(
    private shopDataService: ShopDataService,
    private messageService: MessageService,

  ) {

  }


  ngOnInit(): void {
    this.form = new FormGroup({
      deliveryHoursMondayFrom: new FormControl('', [Validators.required]),
      deliveryHoursMondayTo: new FormControl('', [Validators.required]),
      deliveryHoursMondayClosed: new FormControl('', []),
      deliveryHoursTuesdayFrom: new FormControl('', [Validators.required]),
      deliveryHoursTuesdayTo: new FormControl('', [Validators.required]),
      deliveryHoursTuesdayClosed: new FormControl('', []),
      deliveryHoursWednesdayFrom: new FormControl('', [Validators.required]),
      deliveryHoursWednesdayTo: new FormControl('', [Validators.required]),
      deliveryHoursWednesdayClosed: new FormControl('', []),
      deliveryHoursThursdayFrom: new FormControl('', [Validators.required]),
      deliveryHoursThursdayTo: new FormControl('', [Validators.required]),
      deliveryHoursThursdayClosed: new FormControl('', []),
      deliveryHoursFridayFrom: new FormControl('', [Validators.required]),
      deliveryHoursFridayTo: new FormControl('', [Validators.required]),
      deliveryHoursFridayClosed: new FormControl('', []),
      deliveryHoursSaturdayFrom: new FormControl('', [Validators.required]),
      deliveryHoursSaturdayTo: new FormControl('', [Validators.required]),
      deliveryHoursSaturdayClosed: new FormControl('', []),
      deliveryHoursSundayFrom: new FormControl('', [Validators.required]),
      deliveryHoursSundayTo: new FormControl('', [Validators.required]),
      deliveryHoursSundayClosed: new FormControl('', []),
    }
    );

    this.addClosedListener('deliveryHoursMondayClosed', 'deliveryHoursMondayFrom', 'deliveryHoursMondayTo');
    this.addClosedListener('deliveryHoursTuesdayClosed', 'deliveryHoursTuesdayFrom', 'deliveryHoursTuesdayTo');
    this.addClosedListener('deliveryHoursWednesdayClosed', 'deliveryHoursWednesdayFrom', 'deliveryHoursWednesdayTo');
    this.addClosedListener('deliveryHoursThursdayClosed', 'deliveryHoursThursdayFrom', 'deliveryHoursThursdayTo');
    this.addClosedListener('deliveryHoursFridayClosed', 'deliveryHoursFridayFrom', 'deliveryHoursFridayTo');
    this.addClosedListener('deliveryHoursSaturdayClosed', 'deliveryHoursSaturdayFrom', 'deliveryHoursSaturdayTo');
    this.addClosedListener('deliveryHoursSundayClosed', 'deliveryHoursSundayFrom', 'deliveryHoursSundayTo');

    this.addChangeListenersAndAddMissingValues(
      ['deliveryHoursMondayFrom', 
      'deliveryHoursTuesdayFrom', 
      'deliveryHoursWednesdayFrom', 
      'deliveryHoursThursdayFrom', 
      'deliveryHoursFridayFrom', 
      'deliveryHoursSaturdayFrom', 
      'deliveryHoursSundayFrom']);

      this.addChangeListenersAndAddMissingValues(
        ['deliveryHoursMondayTo', 
        'deliveryHoursTuesdayTo', 
        'deliveryHoursWednesdayTo', 
        'deliveryHoursThursdayTo', 
        'deliveryHoursFridayTo', 
        'deliveryHoursSaturdayTo', 
        'deliveryHoursSundayTo']);

    this.shopDataService.currentShopData.subscribe(shopData => {
      this.shopData = shopData;
      this.form.patchValue(this.shopData);

      this.CHECKBOXES.forEach(name =>{
        var formControl = this.form.get(name);
        if(formControl.value == null || formControl.value == '' || formControl.value == false){
          formControl.setValue(false);
        }
      })
    });

  }

  async saveAndActivateNextStep() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    var newShopData = this.form.value as ShopData;
    newShopData.phone = this.shopData.phone;
    try {
      await this.shopDataService.updateShopData(newShopData);
      this.continue.emit();
    } catch (e) {
      this.messageService.addError("Shopdaten konnten nicht gespeichert werden!");
    }


  }

  previousStep() {
    this.back.emit();
  }


  addClosedListener(deliveryHoursClosed, deliveryHoursFrom, deliveryHoursTo) {
    this.form.get(deliveryHoursClosed).valueChanges.subscribe(val => {
      if (val) {
        var formControl = this.form.get(deliveryHoursFrom);
        if (formControl.value == null || formControl.value == '') {
          formControl.setValue('00:00');
        }
        formControl = this.form.get(deliveryHoursTo);
        if (formControl.value == null || formControl.value == '') {
          formControl.setValue('00:00');
        }
      }
    });
  }

  addChangeListenersAndAddMissingValues(fieldNames: string[]) {
    fieldNames.forEach(fieldName => {
      this.form.get(fieldName).valueChanges.subscribe(val => {
        if (this.disableChangeEvents || val == null || val == '' || val == '00:00') {
          return;
        }

        this.disableChangeEvents = true;

        fieldNames.forEach(otherFieldName => {
          if (otherFieldName == fieldName) {
            return;
          }

          var otherField = this.form.get(otherFieldName);
          if (otherField.value == null || otherField.value == '') {
            otherField.setValue(val)
          }
        });
        this.disableChangeEvents = false;
      })
    });
  }


}
