<form [formGroup]="form">
    <div class="form-container">
        <h5 class="align-self-start pt-3">Zahlungsarten</h5>
        <div class="row">
            <div class="col-md-5 smallpadding-on-mobile">
                <div class="form-group">
   
                    <div class="form-check pt-3">
                        <input type="checkbox" class="form-check-input" id="cash" name="cash" formControlName="cash" >
                        <label class="form-check-label" for="cash">Zahlung bei Abholung / Lieferung</label>
                    </div>   
                    <div class="form-check pt-3">
                        <input type="checkbox" class="form-check-input" id="creditcard" name="creditcard" formControlName="creditcard" >
                        <label class="form-check-label" for="creditcard">Online Kreditkarten Zahlung</label>
                    </div>  
                </div>
            </div>  
        </div>
    </div> 


    <div class="row">
        <button class="btn btn-danger" (click)="previousStep()">Zurück</button>
        <button class="ml-auto btn btn-success" (click)="saveAndActivateNextStep()">Speichern und Dateneingabe abschließen!</button>
    </div>
</form>