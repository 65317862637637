import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from '../../shared/_services/account-service';
import { ErrorWithCode, ERROR_GENERIC, ERROR_OTPTOKENVALIDATIONFAILED } from 'src/app/_errors/error-with-code.error';
import { StartOtpValidationResponse } from 'src/app/_models/start-otp-validation-response.model';
import { CATCH_STACK_VAR } from '@angular/compiler/src/output/output_ast';
import { CurrentUserService } from '../../shared/_services/currentuser.service';
import { AuthData } from 'src/app/_models/auth-data.model';
import { ShopDataService } from './shop-data.service';

export enum SIGNUPSTEP_ENUM {
  S0_PROVIDEEMAILPHONE = 0,
  S1_PROVIDEBASICSHOPDATA = 1,
  S2_SIGNUPCOMPLETED=2
}

@Injectable()
export class SignupStepControllerService {

  public currentStep: BehaviorSubject<number> =
    new BehaviorSubject<number>(SIGNUPSTEP_ENUM.S0_PROVIDEEMAILPHONE);

  private currentPhoneOtpId;
  private currentFreshnessToken;
  private currentEmail;

  constructor(
    private logger: NGXLogger,
    private accountService: AccountService,
    private currentUser:CurrentUserService,
    private shopDataService : ShopDataService
  ) {

    currentUser.authData.subscribe((authData:AuthData) =>{
      if(authData == null){
        this.resetState();  
      } else if (this.currentStep.getValue() == SIGNUPSTEP_ENUM.S0_PROVIDEEMAILPHONE){
        this.currentStep.next(SIGNUPSTEP_ENUM.S1_PROVIDEBASICSHOPDATA);
      }
    });

    shopDataService.currentShopData.subscribe(shopData =>{
      if(shopData != null && shopData.locked){
        this.currentStep.next(SIGNUPSTEP_ENUM.S2_SIGNUPCOMPLETED);
      }
    })

  }

  resetState() {
    this.currentStep.next(SIGNUPSTEP_ENUM.S0_PROVIDEEMAILPHONE);
  }

  nextStep() {
    this.currentStep.next(this.currentStep.getValue() + 1);
  }


  completeSignup(){
    this.nextStep();
  }

  async startOtpValidation(phone, nobottoken) : Promise<boolean> {
    try {
      var authResponse = await this.accountService.startOtpValidation(phone, nobottoken);
      if(authResponse.type == "EMAIL"){
        this.currentPhoneOtpId = authResponse.otpId;
        this.currentFreshnessToken = authResponse.token;
        return false;
      } else {
        this.currentPhoneOtpId = authResponse.otpId;
        return true;        
      }
    } catch (e) {
      this.logger.error("Error starting OTP validation: " + JSON.stringify(e));
      if (e.code)
        throw e;
      else
        throw new ErrorWithCode(ERROR_GENERIC);
    }
  }

  async validateOtpToken(otpToken) {
    if(this.currentPhoneOtpId == null){
      this.logger.error("Unable to validate OTP token without startOtpValidationResponse");
      throw new ErrorWithCode(ERROR_GENERIC);
    }

    try {
      await this.accountService.validateOtpToken(this.currentPhoneOtpId, otpToken);
    } catch (e) {
      this.logger.error("Error validating otp token: " + JSON.stringify(e));
      if(e.code){
        throw e;
      } else {
        throw new ErrorWithCode(ERROR_GENERIC);
      }
    }

  }

}
