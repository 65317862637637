<form [formGroup]="form">
    <div class="form-container">
        <h5 class="align-self-start pt-3">Zustellpreise</h5>
        <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-4 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': form.get('minOrderValue').invalid && form.get('minOrderValue').touched}">
                    <label for="minOrderValue">Mindestbestellwert bei Zustellung</label>
                    <input class="form-control form-control-lg text-center" type="number" id="minOrderValue" name="minOrderValue"
                        formControlName="minOrderValue">
                    <div class="form-control-feedback"
                        *ngIf="form.get('minOrderValue').invalid && form.get('minOrderValue').touched">Bitte gib
                        den Mindestbestellwert an oder 0,00 falls kein Mindestbestellwert vorhanden ist.</div>
                </div>
            </div>     
            <div class="col-md-2 col-sm-4 col-xs-4 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': form.get('noDeliveryCostsOrderValue').invalid && form.get('noDeliveryCostsOrderValue').touched}">
                    <label for="noDeliveryCostsOrderValue">Bestellwert mit kostenloser Zustellung</label>
                    <input class="form-control form-control-lg text-center" type="number" id="noDeliveryCostsOrderValue" name="noDeliveryCostsOrderValue"
                        formControlName="noDeliveryCostsOrderValue">
                    <div class="form-control-feedback"
                        *ngIf="form.get('noDeliveryCostsOrderValue').invalid && form.get('noDeliveryCostsOrderValue').touched">Bitte gib
                        den Bestellwert ein ab dem keine Zustellkosten mehr verrechnet werden</div>
                </div>
            </div>                   
        </div>
        <h5 class="align-self-start pt-3">Zustellbereiche und Gebühren</h5>
        <div class="row" *ngFor="let deliveryArea of deliveryAreas.controls; index as i">
            <div class="col-md-2 col-sm-3 col-xs-4 smallpadding-on-mobile d-flex">
                <div class="form-group"> 
                    <label for="postcode">Postleitzahl</label>
                    <input class="form-control form-control-lg text-center delivery-postcode" type="text" [formControl]="deliveryAreas.controls[i]" >
                </div>
                <div class="form-group pl-2"> 
                    <label for="postcode">Zustellgebühr</label>
                    <input class="form-control form-control-lg text-center delivery-costs" type="number" [formControl]="deliveryCosts.controls[i]" >
                </div>
                <button class="btn btn-danger align-self-center ml-2" (click)="removeDeliveryArea(i)">Entfernen</button>
            </div>     
            <!-- <div class="col-md-2 col-sm-3 col-xs-4 smallpadding-on-mobile">
                <div class="form-group"> 
                    <label for="postcode">Zustellgebühr</label>
                    <input class="form-control form-control-lg text-center" type="text" [formControl]="deliveryCosts.controls[i]" >
                </div>
            </div>     
            <div class="col-md-2 col-sm-3 col-xs-4 smallpadding-on-mobile">
                <button class="btn btn-danger" (click)="removeDeliveryArea(i)">Entfernen</button>
            </div> -->
          
        </div>

        <div class="row">
            <button class="btn btn-primary" (click)="addDeliveryArea()">Zusätzlichen Zustellbereich hinzufügen</button>
        </div>
    </div>


    <div class="row">
        <button class="btn btn-danger" (click)="previousStep()">Zurück</button>
        <button class="ml-auto btn btn-success" (click)="saveAndActivateNextStep()">Speichern und weiter</button>
    </div>
</form>