import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentSignupComponent } from './content-signup/content-signup.component';
import { HowToComponent } from './how-to/how-to.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { SignupStepControllerService } from './_services/signup-step-controller.service';
import { S0ProvidePhoneAndEmailComponent } from './s0-provide-phone-and-email/s0-provide-phone-and-email.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { S1ProvideBasicShopDataComponent } from './s1-provide-basic-shop-data/s1-provide-basic-shop-data.component';
import { ProvideOperatorDataComponent } from './provide-operator-data/provide-operator-data.component';
import { CurrentAccountComponent } from './current-account/current-account.component';
import { ShopDataService } from './_services/shop-data.service';
import { ProvideBaseDataComponent } from './provide-base-data/provide-base-data.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ShopHoursComponent } from './shop-hours/shop-hours.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { DeliveryHoursComponent } from './delivery-hours/delivery-hours.component';
import { DeliveryPricingComponent } from './delivery-pricing/delivery-pricing.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { S2SignupCompletedComponent } from './s2-signup-completed/s2-signup-completed.component';
import { NgxCaptchaModule } from 'src/app/_helpers/ngx-captcha';

@NgModule({
  declarations: [
    ContentSignupComponent,
    HowToComponent,
    S0ProvidePhoneAndEmailComponent,
    S1ProvideBasicShopDataComponent,
    ProvideOperatorDataComponent,
    CurrentAccountComponent,
    ProvideBaseDataComponent,
    ShopHoursComponent,
    DeliveryHoursComponent,
    DeliveryPricingComponent,
    PaymentMethodsComponent,
    S2SignupCompletedComponent,
   
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrettyCheckboxModule,
    LoadingBarModule,
    ModalModule,
    NgxMaterialTimepickerModule,
    NgxCaptchaModule,
  ],
  providers: [
    SignupStepControllerService,
    ShopDataService
  ]
})
export class SignupModule { }
