import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './_modules/shared/shared.module';
import { CoreModule } from './_modules/core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatomoModule } from 'ngx-matomo';
import { SignupModule } from './_modules/signup/signup.module';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { JwtInterceptor } from './jwt.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgxCaptchaModule } from './_helpers/ngx-captcha';
import { ScrollToModule } from './_modules/scroll-to/scroll-to.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG })    ,
    ToastrModule.forRoot({
      maxOpened: 1,
      timeOut: 2000
    }),
    NgxSpinnerModule,
    MatomoModule,
    SignupModule,
    NgxPrettyCheckboxModule,
    ModalModule.forRoot(),
    NgxMaterialTimepickerModule,
    NgxCaptchaModule,
    ScrollToModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
