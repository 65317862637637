import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentSignupComponent } from './_modules/signup/content-signup/content-signup.component';


const routes: Routes = [
  { path: '', component: ContentSignupComponent , pathMatch: 'full' },

  { path: '**', component: ContentSignupComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
