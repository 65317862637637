import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { StartOtpValidationResponse } from 'src/app/_models/start-otp-validation-response.model';
import { NGXLogger } from 'ngx-logger';
import { CurrentUserService } from './currentuser.service';
import { AuthData } from 'src/app/_models/auth-data.model';
import { ERROR_GENERIC } from 'src/app/_errors/error-with-code.error';
import { MessageService } from '../../core/_services/message.service';

@Injectable()
export class AccountService {

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private currentUser: CurrentUserService,
    private messagesService: MessageService
  ) { }

  async startOtpValidation(phone, nobottoken): Promise<StartOtpValidationResponse> {

    try {
      var authResponse = await this.http.post(environment.apiBaseUrl + "/customerbackend/v1/authenticate/byphone/" + encodeURIComponent(nobottoken),
        { phone: phone }).toPromise();

      return authResponse as StartOtpValidationResponse;
    } catch (e) {
      this.logger.error("Error requesting startOtpValidation by phone: " + JSON.stringify(e))

      if (e.error && e.error.code)
        throw e.error;
      else
        throw { code: ERROR_GENERIC };
    }
  }

  async validateOtpToken(otpId, token){
    try {
      var authData = await this.http.post(environment.apiBaseUrl + "/customerbackend/v1/verify/byphone",
        { otpId: otpId, token: token }).toPromise();


      this.currentUser.authData.next(authData as AuthData);

    } catch (e) {
      this.logger.error("Error requesting validateOtpToken by phone: " + JSON.stringify(e))

      if (e.error && e.error.code)
        throw e.error;
      else
        throw { code: ERROR_GENERIC };
    }
  }


  logoutBecauseExpired() {

    this.messagesService.addError("Login Daten abgelaufen. Bitte erneut anmelden")
    this.currentUser.authData.next(null);
  }

}