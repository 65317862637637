import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CrossDomainStorageService } from './cross-domain-storage.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserConsentService {

  public userConsentGiven : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private forceCookieConsent = null;

  constructor(storage:CrossDomainStorageService, private route: ActivatedRoute) { 

    if(!environment.production){
      this.forceCookieConsent = true;
      this.userConsentGiven.next(this.forceCookieConsent);
    }


    route.queryParamMap.subscribe(params => {
      if(params.has("forceCookieConsent")){
        this.forceCookieConsent = true;
        this.userConsentGiven.next(this.forceCookieConsent);
      }
    });

    storage.getItem("userPrivacyConsent", true, true).then((c) => {
      if(this.forceCookieConsent == true){

      } else {
        this.userConsentGiven.next(c?true:false);
      }
    });

    this.userConsentGiven.subscribe((c) => {
      storage.setItem("userPrivacyConsent", c?true:false, true, true );
      storage.userConsentGiven.next(c);
    });

  }

  setUserConsent(consent: boolean){
    this.userConsentGiven.next(consent);
  }
}
