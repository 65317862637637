<section class="page-section" id="services">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h2>Gleich online in 4 einfachen Schritten kostenlos anmelden</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5 fact-container">
                    <fa-icon [icon]="faMapMarker"></fa-icon>
                    <h3 class="h4 mb-2">Mobiltelefonnummer eingeben</h3>
                    <p class="mb-0">Gib Deine Mobiltelefonnummer unten ein - wir senden dir eine SMS mit einem kurzen Code. Um fortzufahren, gib den Code im darunter liegenden Feld ein.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5 fact-container">
                    <fa-icon [icon]="faPizzaSlice"></fa-icon>
                    <h3 class="h4 mb-2">Registrierungsdaten eingeben</h3>
                    <p class="mb-0">Gib die Registrierungsdaten (wie Firmenname, Inhaber, Öffnungszeiten und einiges mehr) für Deinen Webshop ein.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5 fact-container">
                    <fa-icon [icon]="faTruck"></fa-icon>
                    <h3 class="h4 mb-2">Software installieren</h3>
                    <p class="mb-0">Installiere die von uns bereitgestellte Kassensoftware, die automatisch mit deinem Webshop verbunden wird.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5 fact-container">
                    <fa-icon [icon]="faTruck"></fa-icon>
                    <h3 class="h4 mb-2">Loslegen und Bestellungen empfangen</h3>
                    <p class="mb-0">Los gehts! Du kannst Deinen Shop online schalten und Bestellungen von Deinen KundenInnen empfangen.</p>
                </div>
            </div>
        </div>
    </div>
</section>