import { Component } from '@angular/core';
import { UserConsentService } from '../../shared/_services/user-consent.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {


  constructor(private consentService : UserConsentService){

  }

  onShowCookieSettings(){
    this.consentService.setUserConsent(false);
  }

}
