<form [formGroup]="formOperator">
    <div class="form-container">

        <h5 class="align-self-start pt-3">Ich will...</h5>
        <div class="row">
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group i-want-checkboxes"
                    [ngClass]="{'has-danger': formOperator.get('orderPlatforms').invalid && formOperator.get('orderPlatforms').touched}">
                    <input type="checkbox" class="form-check-input i-want-checkbox" id="orderPlatforms" name="orderPlatforms" formControlName="orderPlatforms" >
                    <label class="form-check-label" for="orderPlatforms">Anbindung von Lieferplattformen wie Lieferando, Mjam, Velofood oder ähnlichen bzw. anderen Onlineshop Anbietern</label>
                </div>

                <div class="form-group i-want-checkboxes"
                    [ngClass]="{'has-danger': formOperator.get('orderSpitzAufSchmankerl').invalid && formOperator.get('orderSpitzAufSchmankerl').touched}">
                    <input type="checkbox" class="form-check-input i-want-checkbox" id="orderSpitzAufSchmankerl" name="orderSpitzAufSchmankerl" formControlName="orderSpitzAufSchmankerl" >
                    <label class="form-check-label" for="orderSpitzAufSchmankerl">Eigenen Onlineshop über "Spitz auf Schmankerl"</label>
                </div>
            </div>
        </div>

        <h5 class="align-self-start pt-3">Kontakt</h5>
        <div class="row">
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': formOperator.get('adminEmail').invalid && formOperator.get('adminEmail').touched}">
                    <label for="adminEmail">Kontakt E-Mail Adresse (Rückfragen von uns)</label>
                    <input class="form-control form-control-lg" type="text" id="adminEmail" name="adminEmail" formControlName="adminEmail">
                    <div class="form-control-feedback"
                        *ngIf="formOperator.get('adminEmail').invalid && formOperator.get('adminEmail').touched">Bitte gib
                        deine Kontakt EMail Adresse ein.</div>
                </div>
            </div>
        </div>
        <h5 class="align-self-start pt-3">Firmendaten</h5>
        <div class="row">
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': formOperator.get('company').invalid && formOperator.get('company').touched}">
                    <label for="company">Firmenname (Impressum, Rechnungskopf)</label>
                    <input class="form-control form-control-lg" type="text" id="company" name="company" formControlName="company">
                    <div class="form-control-feedback"
                        *ngIf="formOperator.get('company').invalid && formOperator.get('company').touched">Bitte gib
                        deinen Firmennamen ein.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': formOperator.get('ceo').invalid && formOperator.get('ceo').touched}">
                    <label for="ceo">Geschäftsführer (Impressum, Rechnungskopf)</label>
                    <input class="form-control form-control-lg" type="text" id="ceo" name="ceo" formControlName="ceo">
                    <div class="form-control-feedback"
                    *ngIf="formOperator.get('ceo').invalid && formOperator.get('ceo').touched">Bitte gib Namen des Geschäftsführers ein.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                [ngClass]="{'has-danger': formOperator.get('uid').invalid && formOperator.get('uid').touched}">
                    <label for="uid">UID (Impressum, Rechnungskopf)</label>
                    <input class="form-control form-control-lg" type="text" id="uid" name="uid" formControlName="uid">
                </div>
            </div>
        </div>

        <h5 class="align-self-start pt-3">Firmenanschrift (Impressum)</h5>
        <div class="row">            
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': formOperator.get('companyStreet').invalid && formOperator.get('companyStreet').touched}">
                    <label for="ceo">Straße, Nr</label>
                    <input class="form-control form-control-lg" type="text" id="companyStreet" name="companyStreet" formControlName="companyStreet">
                    <div class="form-control-feedback"
                    *ngIf="formOperator.get('ceo').invalid && formOperator.get('companyStreet').touched">Bitte gib den registrierten Standort der Firma an.</div>
                </div>
            </div>
            <div class="col-md-2 col-sm-4 smallpadding-on-mobile">
                <div class="form-group"
                [ngClass]="{'has-danger': formOperator.get('companyPostCode').invalid && formOperator.get('companyPostCode').touched}">
                    <label for="companyPostCode"> PLZ</label>
                    <input class="form-control form-control-lg" type="text" id="companyPostCode" name="companyPostCode" formControlName="companyPostCode">
                    <div class="form-control-feedback"
                    *ngIf="formOperator.get('companyPostCode').invalid && formOperator.get('companyPostCode').touched">Bitte gib den registrierten Standort der Firma an.</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-8 smallpadding-on-mobile">
                <div class="form-group"
                [ngClass]="{'has-danger': formOperator.get('companyCity').invalid && formOperator.get('companyCity').touched}">
                    <label for="companyCity">Ort</label>
                    <input class="form-control form-control-lg" type="text" id="companyCity" name="companyCity" formControlName="companyCity">
                    <div class="form-control-feedback"
                    *ngIf="formOperator.get('companyCity').invalid && formOperator.get('companyCity').touched">Bitte gib den registrierten Standort der Firma an.</div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <button class="ml-auto btn btn-success" (click)="saveAndActivateNextStep()">Speichern und weiter</button>
    </div>
</form>