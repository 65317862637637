<header class="masthead">
    <div class="container">
        <div class="logo-row">
            <div class="logo"></div>
            <div class="logo-finanzkasse"></div>
        </div>
        <div class="signature row align-items-center justify-content-center text-center">
            <div class="col-lg-10">
                <h1 class="text-white font-weight-bold">Jetzt Bestellungen Online empfangen<div
                        class="highlight-text-on-signature">für Gastronomie und Selbstvermarkter</div></h1>
            </div>
        </div>
    </div>
</header>
