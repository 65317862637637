import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserConsentService } from './_modules/shared/_services/user-consent.service';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public userConsent: boolean = true;
  public realUserConsent: boolean = false;
  public timeoutElapsed = false;
  userConsentSubscription: Subscription;

  constructor(private consentService: UserConsentService, private metaTagService: Meta, private titleService: Title) {
    setTimeout(() => {
      this.timeoutElapsed = true;
      this.userConsent = this.realUserConsent;
    }, 2000);

    titleService.setTitle("SPITZaufSchmankerl - die faire Bestellplattform");

    metaTagService.addTags([
      { name: 'description', content: "SPITZaufSchmankerl - Bestelle dein Essen auf der fairen Bestellplattform, unterstütze die lokale Gastronomie und Selbstvermarkter." },
      { name: 'keywords', content: "SPITZaufSchmankerl, Spitz auf Schmankerl, Online essen bestellen, die faire Bestellplattform, Restaurants, Cafe, Pizza, Kebap, Selbstvermarkter" },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Wiffzackteam UG' },
      { name: 'date', content: formatDate(new Date(), 'yyyy-MM-dd', 'en'), scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }

  ngOnInit(): void {
    this.userConsentSubscription = this.consentService.userConsentGiven.subscribe((c) => {
      if (this.timeoutElapsed)
        this.userConsent = c;
      else
        this.realUserConsent = c;
    }
    );
  }

  ngOnDestroy(): void {
    this.userConsentSubscription.unsubscribe();
  }
}
