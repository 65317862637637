import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ShopData } from 'src/app/_models/shop-data.model';
import { ShopDataService } from '../_services/shop-data.service';
import { MessageService } from '../../core/_services/message.service';
import { createDecimalValidator } from '../_utils/custom-validators';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  public form: FormGroup;

  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  public shopData: ShopData;

  public paymentData = {cash: false, creditcard: false};

  constructor(
    private shopDataService: ShopDataService,
    private messageService: MessageService,

  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      cash: new FormControl('', []),
      creditcard: new FormControl('', []),
    });

    this.shopDataService.currentShopData.subscribe(shopData => {
      this.shopData = shopData;

      if(shopData.paymentMethods == null || shopData.paymentMethods.length == 0){
        this.paymentData = {cash: true, creditcard: false};
      } else {
        for(let paymentMethod of shopData.paymentMethods){
          if(paymentMethod == 'cash'){
            this.paymentData.cash = true;
          } else if(paymentMethod =='creditcard'){
            this.paymentData.creditcard = true;
          }
        }
      }
   
      this.form.patchValue(this.paymentData);
    });
  }

  async saveAndActivateNextStep() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    
    var paymentMethods = this.form.value;
    
    this.shopData.paymentMethods = [];


    if(paymentMethods.cash){
      this.shopData.paymentMethods.push('cash');
    }
    if(paymentMethods.creditcard){
      this.shopData.paymentMethods.push('creditcard');
    }


    try {
      await this.shopDataService.updateShopData(this.shopData);
      this.continue.emit();
    } catch (e) {
      this.messageService.addError("Shopdaten konnten nicht gespeichert werden!");
    }
  }

  previousStep() {
    this.back.emit();
  }

}
