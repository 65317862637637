import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from '../../shared/_services/account-service';
import { ErrorWithCode, ERROR_GENERIC, ERROR_OTPTOKENVALIDATIONFAILED } from 'src/app/_errors/error-with-code.error';
import { StartOtpValidationResponse } from 'src/app/_models/start-otp-validation-response.model';
import { CATCH_STACK_VAR } from '@angular/compiler/src/output/output_ast';
import { CurrentUserService } from '../../shared/_services/currentuser.service';
import { AuthData } from 'src/app/_models/auth-data.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../core/_services/message.service';
import { ShopData } from 'src/app/_models/shop-data.model';


@Injectable()
export class ShopDataService {

  public currentShopData : BehaviorSubject<ShopData> = new BehaviorSubject<ShopData>(null);

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private currentUser:CurrentUserService,
    private messagesService : MessageService,
    private accountService:AccountService
  ) {  
  }

  
  async getShopData() : Promise<ShopData> {
    try {
      var shopdata = await this.http.get(environment.apiBaseUrl + "/customerbackend/v1/shopdata").toPromise();
      this.currentShopData.next(this.mapShopData(shopdata));
      return shopdata as ShopData;
    } catch (e) {
      this.logger.error("Error getting shop data: " + JSON.stringify(e));

      if(e.status && e.status == 401){
        this.accountService.logoutBecauseExpired();
        throw new ErrorWithCode(ERROR_GENERIC);
      }

      if (e.code)
        throw e;
      else
        throw new ErrorWithCode(ERROR_GENERIC);
    }
  } 

  async updateShopData(shopData:ShopData){
    try {
      var newShopData = await this.http.post(environment.apiBaseUrl + "/customerbackend/v1/shopdata", shopData).toPromise();
      this.currentShopData.next(this.mapShopData(newShopData));
    } catch (e) {
      this.logger.error("Error updating shopdata shop data: " + JSON.stringify(e));

      if(e.status && e.status == 401){
        this.accountService.logoutBecauseExpired();
        throw new ErrorWithCode(ERROR_GENERIC);
      }

      if (e.code)
        throw e;
      else
        throw new ErrorWithCode(ERROR_GENERIC);
    }
  }

  private mapShopData(downloadedShopData){
    var shopData = downloadedShopData as ShopData;
    //shopData.deliveryAreas = new Map(downloadedShopData.deliveryAreas);
    return shopData;
  }

  async lockData(){
    try {
      await this.http.post(environment.apiBaseUrl + "/customerbackend/v1/lockdata", null).toPromise();
    } catch (e) {
      this.logger.error("Error updating shopdata shop data: " + JSON.stringify(e));

      if(e.status && e.status == 401){
        this.accountService.logoutBecauseExpired();
        throw new ErrorWithCode(ERROR_GENERIC);
      }

      if (e.code)
        throw e;
      else
        throw new ErrorWithCode(ERROR_GENERIC);
    }
  }
}
