import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ShopDataService } from '../_services/shop-data.service';
import { ShopData } from 'src/app/_models/shop-data.model';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from '../../core/_services/message.service';

@Component({
  selector: 'app-provide-operator-data',
  templateUrl: './provide-operator-data.component.html',
  styleUrls: ['./provide-operator-data.component.scss']
})
export class ProvideOperatorDataComponent implements OnInit, OnDestroy {
  public shopData: ShopData;
  private shopDataSubscription: Subscription;

  public formOperator: FormGroup;

  @Output() continue: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private shopDataService : ShopDataService,
    private messageService : MessageService
    ) { 

  }

  ngOnInit(): void {

    this.formOperator = new FormGroup({
      company: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      uid: new FormControl('',    [Validators.maxLength(25)]),
      ceo: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      adminEmail: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]),
      companyStreet: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      companyPostCode: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.pattern('[0-9]*')]),
      companyCity: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      orderPlatforms: new FormControl('', []),
      orderSpitzAufSchmankerl: new FormControl('', [])
    });

    this.shopDataSubscription = this.shopDataService.currentShopData.subscribe(shopData => {
      this.shopData = shopData;
      if(shopData != null){
        this.formOperator.patchValue(this.shopData);
      }
    });
  }

  ngOnDestroy(): void {
    this.shopDataSubscription.unsubscribe();
  }

  async saveAndActivateNextStep() {
    this.formOperator.markAllAsTouched();
    
    if(!this.formOperator.valid){
      return;
    }

    var newShopData = this.formOperator.value as ShopData;
    newShopData.phone = this.shopData.phone;
    try{
      await this.shopDataService.updateShopData(newShopData);
      this.continue.emit();
    } catch(e){
      this.messageService.addError("Shopdaten konnten nicht gespeichert werden!");      
    }


  }

}
