import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './top-bar/top-bar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ToastContainerModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatomoModule } from 'ngx-matomo';
import { MessageService } from './_services/message.service';


@NgModule({
  declarations: [
    TopBarComponent, 
    HeaderComponent, 
    FooterComponent, 
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ToastContainerModule,
    NgxSpinnerModule,
    MatomoModule
    
  ],
  providers: [
    MessageService
  ],
  exports: [
    HeaderComponent, 
    FooterComponent,
  ]
})
export class CoreModule { }
