import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CrossDomainStorageService } from './cross-domain-storage.service';
import { AuthData } from 'src/app/_models/auth-data.model';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class CurrentUserService { 

    public authData: BehaviorSubject<AuthData>;

    constructor(logger : NGXLogger) {
       
        this.authData = new BehaviorSubject<AuthData>(null);

        var authData = window.localStorage.getItem("auth");
        var authObject = null;

        if(authData != null && authData != "undefined"){
            authObject = JSON.parse(authData);
        }

        this.authData.next(authObject);

        this.authData.subscribe((authData) =>{
            if(authData == null){
                window.localStorage.removeItem("auth");
            } else {
                window.localStorage.setItem("auth", JSON.stringify(authData));
            }
        });
     }
    
}