import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ShopDataService } from '../_services/shop-data.service';
import { MessageService } from '../../core/_services/message.service';
import { ShopData } from 'src/app/_models/shop-data.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SignupStepControllerService } from '../_services/signup-step-controller.service';
import { ScrollToConfigOptions } from '../../scroll-to/scroll-to-config.interface';
import { ScrollToService } from '../../scroll-to/scroll-to.service';

@Component({
  selector: 'app-s1-provide-basic-shop-data',
  templateUrl: './s1-provide-basic-shop-data.component.html',
  styleUrls: ['./s1-provide-basic-shop-data.component.scss']
})
export class S1ProvideBasicShopDataComponent implements OnInit {

  modalRef: BsModalRef;
  
  public stepsPickup = ["1. Betreiberdaten", "2. Basisdaten", "3. Öffnungszeiten", /*"4. Hintergrundbild", "5. Logo",*/ "4. Zahlungsarten"];
  public stepsDeliveryAndPickup = ["1. Betreiberdaten", "2. Basisdaten", "3. Öffnungszeiten", "3a. Zustellzeiten", "3b. Preisgestaltung ", /*"4. Hintergrundbild", "5. Logo",*/ "4. Zahlungsarten"];
  public steps = [];
  public activeStep = 0;

  public shopData : ShopData = null;

  @ViewChild
  ('backTemplate') backTemplate;

  constructor(private logger : NGXLogger,
    private shopDataService : ShopDataService,
    private messages : MessageService,
    private modalService: BsModalService,
    private signupStepController : SignupStepControllerService,
    private scrollToService : ScrollToService
    ) { }

  ngOnInit(): void {
    this.steps = this.stepsDeliveryAndPickup;

    //TODO: block with spinner
    this.shopDataService.getShopData()
    .then(shopData =>
      {
        this.shopData = shopData;

       
      })
    .catch(e => {
      this.messages.addError("Shop Daten konnten nicht abgerufen werden, laden sie die Seite neu und versuchen sie es erneut");
    });

    this.shopDataService.currentShopData.subscribe(shopData => {
      this.shopData = shopData;
      if(shopData != null && shopData.shopPickupDelivery == "pickup"){
        this.steps = this.stepsPickup;
      } else {
        this.steps = this.stepsDeliveryAndPickup;
      }
    })
  }

  activateNextStep(){
    this.activeStep = this.activeStep + 1;
    this.scrollToTop();
  }

  async complete(){
    await this.shopDataService.lockData();
    this.signupStepController.completeSignup();
  }

  previousStep(){
    this.showPreviousStepConfirmation(this.backTemplate);
  }

  scrollToTop(){
    const config: ScrollToConfigOptions = {
      target: '#scrollto',
      duration: 1000,
      easing: 'easeInOutQuart',
      offset: -130
    };
    
    this.scrollToService.scrollTo(config);  
   

  }

  showPreviousStepConfirmation(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    
  }
 
  confirmPreviousStep(): void {
    this.activeStep = this.activeStep - 1;
    this.modalRef.hide();
    this.scrollToTop();
  }
 
  declinePreviousStep(): void {
    this.modalRef.hide();
  }

  isDelivery() {
    if(this.shopData == null)
      return true;

    return this.shopData.shopPickupDelivery == "pickupanddelivery" || this.shopData.shopPickupDelivery == "delivery";
  }

  getDeliveryOffset() {
    return this.isDelivery()?0:2;
  }
}
