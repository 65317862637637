<div id="scrollto" class="container">
    <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-10 mt-4">
            <h1>Jetzt kostenlos anmelden</h1>
        </div>
    </div>
</div>

<div class="jumbotron">
    <app-s0-provide-phone-and-email *ngIf="s0Visible"></app-s0-provide-phone-and-email>
    <app-s1-provide-basic-shop-data *ngIf="s1Visible"></app-s1-provide-basic-shop-data>
    <app-s2-signup-completed *ngIf="s2Visible"></app-s2-signup-completed>
</div>

<app-how-to></app-how-to>