import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Address } from 'src/app/_models/address.model';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxPrettyCheckboxComponent } from 'ngx-pretty-checkbox';
import { AccountService } from '../../shared/_services/account-service';
import { SignupStepControllerService, SIGNUPSTEP_ENUM } from '../_services/signup-step-controller.service';
import { ScrollToService } from '../../scroll-to/scroll-to.service';
import { ScrollToConfigOptions } from '../../scroll-to/scroll-to-config.interface';

@Component({
  selector: 'app-content-signup',
  templateUrl: './content-signup.component.html',
  styleUrls: ['./content-signup.component.scss'],
  animations: [
    trigger('fadeS1', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({opacity: 0}), animate(600)])
      //transition('true => false', animate("0ms ease-in"))
    ]),
    trigger('showResultsContainer', [
      state('true', style({ minHeight: '10rem' })),
      state('false', style({ height: '0px' })),
      transition('false => true', animate("300ms ease-in")),
      //transition('true => false', animate("0ms ease-in"))
    ])
  ]
})
export class ContentSignupComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  
  public s0Visible : boolean;
  public s1Visible : boolean;
  public s2Visible : boolean;


  
  constructor(
    private logger: NGXLogger,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private signupController : SignupStepControllerService,
    private scrollToService : ScrollToService
  ) { }

  ngOnInit(): void {
    const config: ScrollToConfigOptions = {
      target: '#scrollto',
      duration: 1000,
      easing: 'easeInOutQuart',
      offset: -130
    };

    this.signupController.currentStep.subscribe((step) => {
      this.s0Visible = step==SIGNUPSTEP_ENUM.S0_PROVIDEEMAILPHONE
      this.s1Visible = step==SIGNUPSTEP_ENUM.S1_PROVIDEBASICSHOPDATA
      this.s2Visible = step==SIGNUPSTEP_ENUM.S2_SIGNUPCOMPLETED

      if(!this.s0Visible){
        this.scrollToService.scrollTo(config);  
      }
     
    });

    
  }


  ngOnDestroy(): void {

  }




}
