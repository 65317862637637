import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxPrettyCheckboxComponent } from 'ngx-pretty-checkbox';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from '../../shared/_services/account-service';
import { SignupStepControllerService } from '../_services/signup-step-controller.service';
import { ERROR_GENERIC, ERROR_OTPTOKENVALIDATIONFAILED, NOTAVALIDTOKEN, NOTANAUSTRIANPHONENUMER, NOTAMOBILENUMBER, INVALID_PHONENUMBER, TOOMANYPHONELOGINS, TOOMANYMAILLOGINS, TOOMANYRETRIES } from 'src/app/_errors/error-with-code.error';
import { ReCaptcha2Component } from 'src/app/_helpers/ngx-captcha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-s0-provide-phone-and-email',
  templateUrl: './s0-provide-phone-and-email.component.html',
  styleUrls: ['./s0-provide-phone-and-email.component.scss'],
  animations: [
    trigger('showCode', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px', display: 'none' })),
      transition('false <=> true', animate("100ms ease-in")),
      //transition('true => false', animate("0ms ease-in"))
    ])]
})
export class S0ProvidePhoneAndEmailComponent implements OnInit, OnDestroy {

  public errorMessage = null;
  public codeErrorMessage = null;
  public disabled = false;
  public formPhoneEmail: FormGroup;
  public formCode: FormGroup;
  
  public showCode = false;
  
  public formCodeChangeSubscription: Subscription;
  

  @ViewChild('tosCheckbox')
  private tosCheckbox: NgxPrettyCheckboxComponent;
  public showTosError: boolean = true;

  //ReCAPTCHA
  public showCaptureError = false;
  public readonly siteKey = '6LeN3fcUAAAAANOpi7--92zzYo9k2AkVHRZ3MHwu';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'de';
  public type: 'image' | 'audio' = "image";
  public useGlobalDomain: boolean = false;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;


  constructor(
    private logger: NGXLogger,
    private accountService: AccountService,
    private signupStepController: SignupStepControllerService
  ) {
  }

  ngOnDestroy(): void {
    if (this.formCodeChangeSubscription != null) {
      this.formCodeChangeSubscription.unsubscribe();
      this.formCodeChangeSubscription = null;
    }
  }

  ngOnInit(): void {
    this.formPhoneEmail = new FormGroup({
      // email: new FormControl('',
      //   [Validators.required]),
      phone: new FormControl('',
        [Validators.required,
        Validators.pattern("^((\\+43-?)|0)?[0-9]*$")
        ]
      )
    });

    this.formCode = new FormGroup({
      code: new FormControl('',
        [Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)])
    });

    this.formCodeChangeSubscription = this.formCode.valueChanges.subscribe((formGroup) => {
      this.errorMessage = null;
      this.codeErrorMessage = null;
      if (formGroup.code.length > 6) {
        this.formCode.setValue({ code: formGroup.code.substring(0, 6) });
      } else if (formGroup.code.length == 6 && !this.formCode.get('code').disabled) {
        this.formCode.get('code').disable();
        this.otpCodeValidation(this.formCode.get('code').value);
      }

    });
  }

  async startPhoneValidation() {
    if (environment.requireCapture && !this.captchaSuccess) {
      this.showCaptureError = true;
      return;
    }


    this.errorMessage = null;
    this.codeErrorMessage = null;
    if (!this.formPhoneEmail.valid) {
      this.formPhoneEmail.markAllAsTouched();
      return;
    }


    if(this.showTosError){
      this.errorMessage = "Bitte bestätige dass du mit den AGBs, Datenschutzbestimmungen sowie der Partnervereinbarung einverstanden bist und diesen zustimmst";
      return;
    }

    this.disabled = true;
    // this.formPhoneEmail.get('email').disable();
    this.formPhoneEmail.get('phone').disable();

    var nobottoken = this.captchaResponse;
    try {
      var showPhoneCodeValidation = await this.signupStepController.startOtpValidation(
        this.formPhoneEmail.get('phone').value, nobottoken);
        // this.formPhoneEmail.get('email').value);
        this.showCode = true;
    } catch (e) {
      this.logger.error(JSON.stringify(e));
      this.showCode = false;
      this.disabled = false;
      // this.formPhoneEmail.get('email').enable();
      this.formPhoneEmail.get('phone').enable();

      this.errorMessage = "Der Code konnte nicht angefordert werden. Bitte überprüfe deine Internetverbindung und versuche es erneut.";
      if(e.code){
        if(e.code == ERROR_GENERIC){
          this.errorMessage = "Der Code konnte nicht angefordert werden. Bitte überprüfe deine Internetverbindung und versuche es erneut.";
        }
        else if(e.code == NOTANAUSTRIANPHONENUMER){
          this.errorMessage = "Bitte gib eine österreichische Mobiltelefonnummer ein";
        }
        else if(e.code == NOTAMOBILENUMBER){
          this.errorMessage = "Bitte gib eine österreichische Mobiltelefonnummer ein";
        }
        else if(e.code == INVALID_PHONENUMBER){
          this.errorMessage = "Bitte gib eine gültige Mobiltelefonnummer ein";
        }
        else if(e.code == TOOMANYPHONELOGINS){
          this.errorMessage = "Dein Account wurde wegen zu vielen Anmeldeversuchen gesperrt. Bitte kontaktieren den Support unter 0664/5501000";
        }
        else if(e.code == TOOMANYMAILLOGINS){
          this.errorMessage = "Dein Account wurde wegen zu vielen Anmeldeversuchen gesperrt. Bitte kontaktieren den Support unter 0664/5501000";
        }
        
      }
      
    }

  }

  async otpCodeValidation(code: String) {
    try{
      await this.signupStepController.validateOtpToken(code);
      
    } catch(e){
      this.logger.error(JSON.stringify(e));
      this.formCode.setValue({code:''});
      this.formCode.get('code').enable();      
      this.codeErrorMessage = "Die Codeüberprüfung konnte nicht durchgeführt werden. Bitte überprüfe deine Internetverbindung.";      


      if(e.code){
        if(e.code == ERROR_OTPTOKENVALIDATIONFAILED){
          this.codeErrorMessage = "Der eingegebene Code ist nicht korrekt. Bitte überprüfe deine Eingabe";                          
        } else if(e.code == NOTAVALIDTOKEN){
          this.codeErrorMessage = "Es wurde ein falscher Code eingegeben";
        } else if(e.code  == TOOMANYRETRIES){
          this.codeErrorMessage = "Zu viele versuche. Bitte lade die Seite neu und versuche es nochmals";
        }
      }
    }
  }

  public onCheckedTos() {
    this.showTosError = !this.tosCheckbox.checked;

    
  }


  //RECAPTCHA
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    //this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.showCaptureError = false;
    //this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.showCaptureError = false;
    //this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    //this.cdr.detectChanges();
  }
  //END RECAPTCHA
}
