<div class="consent-bg">
</div>
<!-- <div class="consent-wrapper"> -->
    <div class="consent-container">
        <div class="header-line">
        <div class="platform-logo"><img src="assets/img/logo.svg" /></div>
        <h1 class="header">Diese Seite verwendet Cookies bzw. vergleichbare Technologien</h1>
    </div>
        <div class="details">Wir verwenden keine Third-Party Cookies die dich über mehrere Seite tracken und zeigen dir
            keine personalisierte Werbeeinschaltungen Dritter. Die von dir getätigten Eingaben werden in deinem Browser zwischengespeichert um
            die Bedienung zu angenehm und effizient wie möglich zu gestalten.
            Wir verwenden Google reCAPTCHA -
            diese kann auch Cookies setzen und wird von uns verwendet um sicherzustellen dass das Service nicht
            mißbräuchlich verwendet wird. Mehr Informationen findest du in unserer Datenschutzerklärung.
        </div>
        <button class="btn btn-success btn-sm " (click)="onConsent()">Ich stimme zu</button>
    </div>
<!-- </div> -->