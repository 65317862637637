import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ShopData } from 'src/app/_models/shop-data.model';
import { ShopDataService } from '../_services/shop-data.service';
import { MessageService } from '../../core/_services/message.service';
import { createUrlValidator, createDecimalValidator } from '../_utils/custom-validators';

@Component({
  selector: 'app-delivery-pricing',
  templateUrl: './delivery-pricing.component.html',
  styleUrls: ['./delivery-pricing.component.scss']
})
export class DeliveryPricingComponent implements OnInit {

  public form: FormGroup;

  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  public shopData: ShopData;

  public deliveryAreas: FormArray = new FormArray([]);
  public deliveryCosts: FormArray = new FormArray([]);

  constructor(
    private shopDataService: ShopDataService,
    private messageService: MessageService,

  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      minOrderValue: new FormControl('', [Validators.required, createDecimalValidator()]),
      noDeliveryCostsOrderValue: new FormControl('', [Validators.required, createDecimalValidator()]),
    });

    this.shopDataService.currentShopData.subscribe(shopData => {
      this.shopData = shopData;

      this.deliveryAreas = new FormArray([]);
      this.deliveryCosts = new FormArray([]);
      if (shopData != null) {
        for(let i = 0; i<shopData.deliveryAreas.length; i++){
       var postcodeFormControl = new FormControl('');
          var costsFormControl = new FormControl('');
          console.log(shopData.deliveryAreas[i]);
          console.log(shopData.deliveryCosts[i]);
          postcodeFormControl.setValue(shopData.deliveryAreas[i]);
          costsFormControl.setValue(shopData.deliveryCosts[i]);
          this.deliveryAreas.push(postcodeFormControl);
          this.deliveryCosts.push(costsFormControl);
        }
      }

      this.form.patchValue(this.shopData);
    });
  }

  addDeliveryArea(){
    this.deliveryAreas.push(new FormControl(''));
    this.deliveryCosts.push(new FormControl(''));
  }

  removeDeliveryArea(index){
    this.deliveryAreas.removeAt(index);
    this.deliveryCosts.removeAt(index);
  }

  async saveAndActivateNextStep() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    
    var newShopData = this.form.value as ShopData;
    newShopData.phone = this.shopData.phone;
    
    var deliveryAreasArray:String[] = [];
    var deliveryCostsArray:number[] = [];

    for(var i = 0; i<this.deliveryCosts.controls.length; i++){
      var postcode = this.deliveryAreas.controls[i].value;
      var deliveryCosts = this.deliveryCosts.controls[i].value;

      if(postcode != null && postcode.length == 4 && deliveryCosts != null && deliveryCosts != ''){
        deliveryAreasArray.push(postcode);
        deliveryCostsArray.push(deliveryCosts as number);
      }
    }

    newShopData.deliveryAreas = deliveryAreasArray;
    newShopData.deliveryCosts = deliveryCostsArray;

    try {
      await this.shopDataService.updateShopData(newShopData);
      this.continue.emit();
    } catch (e) {
      this.messageService.addError("Shopdaten konnten nicht gespeichert werden!");
    }
  }

  previousStep() {
    this.back.emit();
  }
}
