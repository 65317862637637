<div id="scroll-into-view"></div>
<app-current-account></app-current-account>

<div class="signup-form-container-full-width padding-bottom-3x mb-2">
    <!-- <div class="row"> -->
        <div class="col-md-12">
            <div class="checkout-steps">
                <button [ngClass]="{'active': i === activeStep, 'active-sibling': i === activeStep-1}"
                    *ngFor="let step of steps; let i = index"><span class="angle"
                        *ngIf="i < steps.length-1" ></span>{{step}}</button>
            </div>              
        </div>
    <!-- </div> -->

    <div class="col-md-12 border-top mt-3">
        <app-provide-operator-data class="provide-data" *ngIf="activeStep==0" (continue)="activateNextStep()"></app-provide-operator-data>        
        <app-provide-base-data class="provide-data" *ngIf="activeStep==1" (back)="previousStep()" (continue)="activateNextStep()"></app-provide-base-data>        
        <app-shop-hours class="provide-data" *ngIf="activeStep==2" (back)="previousStep()" (continue)="activateNextStep()"></app-shop-hours>        
        <app-delivery-hours class="provide-data" *ngIf="activeStep==3 && isDelivery()" (back)="previousStep()" (continue)="activateNextStep()"></app-delivery-hours>        
        <app-delivery-pricing class="provide-data" *ngIf="activeStep==4 && isDelivery()" (back)="previousStep()" (continue)="activateNextStep()"></app-delivery-pricing>        
        <app-payment-methods class="provide-data" *ngIf="(activeStep + getDeliveryOffset())==5" (back)="previousStep()" (continue)="complete()"></app-payment-methods>        
    </div>

</div>


<ng-template #backTemplate>
    <div role="document" class="modal-dialog modal-sm">
        <div class="modal-content">
    <div class="modal-body text-center">
      <p>Die Eingaben die du in diesem Schritt getätigt hast werden verworfen. Möchtest du wirklich fortfahren?</p>
      <button type="button" class="btn btn-danger" (click)="confirmPreviousStep()" >Weiter</button>
      <button type="button" class="btn btn-success" (click)="declinePreviousStep()" >Abbrechen</button>
    </div>
    </div>
    </div>
  </ng-template>