<form [formGroup]="formPhoneEmail">
    <div class="signup-form-container">

        <div class="col-md-6 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': formPhoneEmail.get('phone').invalid && formPhoneEmail.get('phone').touched}">
                <label for="phone">Mobiltelefonnummer</label>
                <input class="form-control form-control-lg" type="text" id="phone" name="phone" formControlName="phone">
                <div class="form-control-feedback"
                    *ngIf="formPhoneEmail.get('phone').invalid && formPhoneEmail.get('phone').touched">Bitte gib
                    deine Mobiltelefonnummer ein im Format: 066400000000</div>
            </div>
        </div>       
        <div class="tos col-md-6 d-flex justify-content-start align-items-center">
            <p-checkbox #tosCheckbox outline="true" color="primary" animation="smooth" bigger="true"
                (change)="onCheckedTos()"></p-checkbox>
            <span (click)="tosCheckbox.checked = !tosCheckbox.checked; onCheckedTos();">Du akzeptierst unsere <a
                    href="https://info.spitzaufschmankerl.at/agb.html" target="_blank">Allgemeinen Geschäftsbedingungen,
                    sowie Datenschutzbestimmungen</a>, gibst Dein Einverständnis zu unserer
                    <a href="https://info.spitzaufschmankerl.at/assets/SPITZaufSchmankerl-Partnervereinbarung.pdf" target="_blank">Partnervereinbarung</a> und bestätigst deine Geschäftsfähigkeit.</span>
        </div>
        
        <div class="error-message text-center pt-3 text-bold">{{errorMessage}}</div>

        <div class="col-sm-6 text-sm-right pt-4">
            <ngx-recaptcha2 #captchaElem
            style="display:inline-block"
            [siteKey]="siteKey"
            [useGlobalDomain]="false"
            [size]="size"
            [hl]="lang"
            [theme]="theme"
            [type]="type"
              (reset)="handleReset()"
              (expire)="handleExpire()"
              (load)="handleLoad()"
              (success)="handleSuccess($event)"              
              formControlName="recaptcha">
            </ngx-recaptcha2>
            </div>
            <div class="form-group" [ngClass]="{'has-danger': showCaptureError}">
                <div class="form-control-feedback" *ngIf="showCaptureError">
                  Bitte clicke auf "Ich bin kein Roboter" um den Registrierungsprozess zu starten
                </div>
              </div>
        <div class="tos col-md-6 d-flex justify-content-start align-items-center pt-0">
            Mit Klick auf folgenden Button stimmst du zu, dass wir Deine Telefonnummer und E-Mail Adresse auf Echtheit verifizieren und von unseren Partnern eine Verifikationsnachricht senden lassen.
        </div>
        <button class="btn btn-success" href="" (click)="startPhoneValidation()" [disabled]="disabled">Mobiletelefonnummer bestätigen</button>
    </div>
</form>

<form [formGroup]="formCode" *ngIf="showCode">
    <div class="form-container">
        <div>
            <div class="input-group codegroup">
                <label for="email">SMS Code</label>
                <div class="input-group-addon">
                    <span class="input-group-text" id="basic-addon1"><img class="pt-2" src="assets/img/loading.gif"
                            width="30px"></span>
                </div>
                <input type="text" class="form-control" placeholder="Code" aria-label="Code"
                    aria-describedby="basic-addon1" formControlName="code">
            </div>
        </div>
    </div>
</form>
<div class="error-message text-center">{{codeErrorMessage}}</div>