<form [formGroup]="form">
    <div class="form-container">
        <h5 class="align-self-start pt-3">Webshop Basiseinstellungen</h5>
        <div class="row">
            <div class="col-md-6 smallpadding-on-mobile">
                <label for="company">Online oder telefonisch?</label>
                <div class="form-group">
                    <div class="dropdown">
                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span *ngIf="shopData.shopPackage == 'online'">Online Bestellung</span>
                            <span *ngIf="shopData.shopPackage == 'phone'">Telefonische Bestellung</span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button class="dropdown-item" (click)="onPackageSelected('online')">Online Bestellung</button>
                            <button class="dropdown-item" (click)="onPackageSelected('phone')">Telefonische Bestellung</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <label for="company">Abholung oder Zustellung?</label>
                <div class="form-group">
                    <div class="dropdown">
                        <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span *ngIf="shopData.shopPickupDelivery == 'pickupanddelivery'">Abholung und Zustellung</span>
                            <span *ngIf="shopData.shopPickupDelivery == 'pickup'">Nur Abholung</span>
                            <span *ngIf="shopData.shopPickupDelivery == 'delivery'">Nur Zustellung</span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button class="dropdown-item" (click)="onPickupDeliverySelected('pickupanddelivery')">Abholung und Zustellung</button>
                            <button class="dropdown-item" (click)="onPickupDeliverySelected('pickup')">Nur Abholung</button>
                            <button class="dropdown-item" (click)="onPickupDeliverySelected('delivery')">Nur Zustellung</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': form.get('urlPrefix').invalid && form.get('urlPrefix').touched}">
                    <label for="urlPrefix">Webshop URL</label>
                    <div class="d-flex ">
                        <input #urlPrefix class="form-control form-control-lg" type="text" id="urlPrefix" name="urlPrefix"
                            formControlName="urlPrefix">
                            
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span *ngIf="shopData != null">.{{shopData.shopDomain}}</span>
                            </button>
                            
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <button class="dropdown-item"
                                    (click)="onSelectShopDomain('spitzaufschmankerl.at')">spitzaufschmankerl.at</button>
                                <button class="dropdown-item"
                                    (click)="onSelectShopDomain('spitzaufpizza.at')">spitzaufpizza.at</button>
                                <button class="dropdown-item"
                                    (click)="onSelectShopDomain('spitzaufburger.at')">spitzaufburger.at</button>
                                <button class="dropdown-item"
                                    (click)="onSelectShopDomain('spitzaufkebap.at')">spitzaufkebap.at</button>
                                <button class="dropdown-item"
                                    (click)="onSelectShopDomain('spitzaufdöner.at')">spitzaufdöner.at</button>
                                <button class="dropdown-item"
                                    (click)="onSelectShopDomain('spitzaufsushi.at')">spitzaufsushi.at</button>
                                <button class="dropdown-item"
                                    (click)="onSelectShopDomain('spitzaufstrudel.at')">spitzaufstrudel.at</button>
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-control-feedback"
                            *ngIf="form.get('urlPrefix').invalid && form.get('urlPrefix').touched">
                            Gib den gewünschten vorderen Teil deiner gewünschten Web Adresse an (nur Buchstaben, Zahlen
                            und Bindestrich) </div>
                    <div *ngIf="shopData!=null && urlPrefix.value != null && urlPrefix.value.length > 0">Dein Webshop wird unter <b>https://{{urlPrefix.value}}.{{shopData.shopDomain}}</b> erreichbar sein.</div>
                </div>
            </div>
        </div>
        <h5 class="align-self-start pt-3">Kopfzeile - Schnellinformationen</h5>
        <div class="row">
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': form.get('topbarCompany').invalid && form.get('topbarCompany').touched}">
                    <label for="topbarCompany">Firma - Anzeigename (einzeilig)</label>
                    <input class="form-control form-control-lg" type="text" id="topbarCompany" name="topbarCompany"
                        formControlName="topbarCompany">
                    <div class="form-control-feedback"
                        *ngIf="form.get('topbarCompany').invalid && form.get('topbarCompany').touched">Bitte gib
                        deinen Firmennamen ein der in der Kopfzeile angezeigt wird.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group" 
                [ngClass]="{'has-danger': form.get('customerPhone').invalid && form.get('customerPhone').touched}">
                    <label for="customerPhone">Telefonnummer für Kundenrückfragen</label>
                    <input class="form-control form-control-lg" type="text" id="customerPhone" name="customerPhone" formControlName="customerPhone">
                    <div class="form-control-feedback"
                        *ngIf="form.get('customerPhone').invalid && form.get('customerPhone').touched">Bitte gib
                        die Telefonnummer für Kundenrückfragen ein.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': form.get('websiteUrl').invalid && form.get('websiteUrl').touched}">
                    <label for="websiteUrl">Bestehende Website URL (http://beispiel.com)</label>
                    <input class="form-control form-control-lg" type="text" id="websiteUrl" name="websiteUrl"
                        formControlName="websiteUrl">
                    <div class="form-control-feedback"
                        *ngIf="form.get('websiteUrl').invalid && form.get('websiteUrl').touched">Bitte gib
                        deine bereits bestehende Website URL ein</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group" 
                [ngClass]="{'has-danger': form.get('facebookUrl').invalid && form.get('facebookUrl').touched}">
                    <label for="facebookUrl">Facebook URL (https://www.facebook.com/deinname)</label>
                    <input class="form-control form-control-lg" type="text" id="facebookUrl" name="facebookUrl" formControlName="facebookUrl">
                    <div class="form-control-feedback"
                        *ngIf="form.get('facebookUrl').invalid && form.get('facebookUrl').touched">Bitte gib
                        die URL deiner bestehenden Facebook Seite ein.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group" 
                [ngClass]="{'has-danger': form.get('instagramUrl').invalid && form.get('instagramUrl').touched}">
                    <label for="instagramUrl">Instagram URL (https://www.instagram.com/deinname)</label>
                    <input class="form-control form-control-lg" type="text" id="instagramUrl" name="instagramUrl" formControlName="instagramUrl">
                    <div class="form-control-feedback"
                        *ngIf="form.get('instagramUrl').invalid && form.get('instagramUrl').touched">Bitte gib
                        die URL deiner bestehenden Instagram Seite ein.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group" 
                [ngClass]="{'has-danger': form.get('topbarShopHours').invalid && form.get('topbarShopHours').touched}">
                    <label for="topbarShopHours">Kurzform deiner Öffnungszeiten (z.B.: Mi. - So. 11:00 - 23:00)</label>
                    <input class="form-control form-control-lg" type="text" id="topbarShopHours" name="topbarShopHours" formControlName="topbarShopHours">
                    <div class="form-control-feedback"
                        *ngIf="form.get('topbarShopHours').invalid && form.get('topbarShopHours').touched">Gib deine Öffnungszeiten in leicht lesbarer Form ein z.B. Mi. - So. 11:00 - 23:00</div>
                </div>
            </div>
        </div>
        <h5 class="align-self-start pt-3">Detailanzeige</h5>
        <div class="row">
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group"
                    [ngClass]="{'has-danger': form.get('mainCompany').invalid && form.get('mainCompany').touched}">
                    <label for="mainCompany">Firma - Anzeigename 1.Zeile</label>
                    <input class="form-control form-control-lg" type="text" id="mainCompany" name="mainCompany"
                        formControlName="mainCompany">
                    <div class="form-control-feedback"
                        *ngIf="form.get('mainCompany').invalid && form.get('mainCompany').touched">Bitte gib die 1.Zeile deines Firmennamens ein.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group" 
                [ngClass]="{'has-danger': form.get('mainCompany2').invalid && form.get('mainCompany2').touched}">
                    <label for="mainCompany2">Firma - Anzeigename 2.Zeile</label>
                    <input class="form-control form-control-lg" type="text" id="mainCompany2" name="mainCompany2" formControlName="mainCompany2">
                    <div class="form-control-feedback"
                        *ngIf="form.get('mainCompany2').invalid && form.get('mainCompany2').touched">Bitte gib
                        die 2. Zeile deines Firmennamens ein.</div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group" 
                [ngClass]="{'has-danger': form.get('mainLocation').invalid && form.get('mainLocation').touched}">
                    <label for="mainLocation">Lokal Standort (Standortangabe für Kunden)</label>
                    <input class="form-control form-control-lg" type="text" id="mainLocation" name="mainLocation" formControlName="mainLocation">
                    <div class="form-control-feedback"
                        *ngIf="form.get('mainLocation').invalid && form.get('mainLocation').touched">Bitte gib die Adresse deines Lokals so wie es dem Kunden angezeigt werden soll an (z.B. Straße Nr., 1010 Wien) </div>
                </div>
            </div>
            <div class="col-md-6 smallpadding-on-mobile">
                <div class="form-group" 
                [ngClass]="{'has-danger': form.get('mainDescription').invalid && form.get('mainDescription').touched}">
                    <label for="mainDescription">Kurzbeschreibung deines Lokals - Warum sollen Kunden bei dir bestellen?</label>
                    <textarea class="form-control form-control-lg" type="text" id="mainDescription" name="mainDescription" formControlName="mainDescription"></textarea>
                    <div class="form-control-feedback"
                        *ngIf="form.get('mainDescription').invalid && form.get('mainDescription').touched">Bitte gib eine kurze Beschreibung deines Lokals an. Was macht die besonders? Warum sollen Kunden gerade bei dir bestellen?</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <button class="btn btn-danger" (click)="previousStep()">Zurück</button>
        <button class="ml-auto btn btn-success" (click)="saveAndActivateNextStep()">Speichern und weiter</button>
    </div>
</form>

