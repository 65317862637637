import { Validators } from '@angular/forms';

const urlPattern = '^(https?:\\/\\/)'+ // protocol
    '((([a-zöäü\\d]([a-zöäü\\d-]*[a-zöäü\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$';

const decimalPattern = '^\\d*[\\.,]?\\d{0,2}$';

export function createUrlValidator(){
    return Validators.pattern(urlPattern);
}

export function createDecimalValidator(){
    return Validators.pattern(decimalPattern);
}

