<form [formGroup]="form">

    <h5 class="align-self-start pt-3">Lieferzeiten</h5>
    <div>Gib deine detaillierten Lieferzeiten als Information für deine Kunden an. Unabhängig der Öffnungszeiten kann
        der Shop jederzeit geschlossen bzw. geöffnet werden.</div>
    <div class="row pt-4">
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('deliveryHoursMondayFrom').invalid && form.get('deliveryHoursMondayFrom').touched) || (form.get('deliveryHoursMondayTo').invalid && form.get('deliveryHoursMondayTo').touched)}">
                <label for="deliveryHoursMondayFrom">Montag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursMondayFrom"
                        name="deliveryHoursMondayFrom" formControlName="deliveryHoursMondayFrom" [ngxTimepicker]="pickerMondayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursMondayTo"
                        name="deliveryHoursMondayTo" formControlName="deliveryHoursMondayTo" [ngxTimepicker]="pickerMondayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerMondayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerMondayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="deliveryHoursMondayClosed" name="deliveryHoursMondayClosed" formControlName="deliveryHoursMondayClosed" >
                    <label class="form-check-label" for="deliveryHoursMondayClosed">Keine Lieferung</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('deliveryHoursMondayFrom').invalid && form.get('deliveryHoursMondayFrom').touched) || form.get('deliveryHoursMondayTo').invalid && form.get('deliveryHoursMondayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('deliveryHoursTuesdayFrom').invalid && form.get('deliveryHoursTuesdayFrom').touched) || (form.get('deliveryHoursTuesdayTo').invalid && form.get('deliveryHoursTuesdayTo').touched)}">
                <label for="deliveryHoursTuesdayFrom">Dienstag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursTuesdayFrom"
                        name="deliveryHoursTuesdayFrom" formControlName="deliveryHoursTuesdayFrom" [ngxTimepicker]="pickerTuesdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursTuesdayTo"
                        name="deliveryHoursTuesdayTo" formControlName="deliveryHoursTuesdayTo" [ngxTimepicker]="pickerTuesdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerTuesdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerTuesdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="deliveryHoursTuesdayClosed" name="deliveryHoursTuesdayClosed" formControlName="deliveryHoursTuesdayClosed" >
                    <label class="form-check-label" for="deliveryHoursTuesdayClosed">Keine Lieferung</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('deliveryHoursTuesdayFrom').invalid && form.get('deliveryHoursTuesdayFrom').touched) || form.get('deliveryHoursTuesdayTo').invalid && form.get('deliveryHoursTuesdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('deliveryHoursWednesdayFrom').invalid && form.get('deliveryHoursWednesdayFrom').touched) || (form.get('deliveryHoursWednesdayTo').invalid && form.get('deliveryHoursWednesdayTo').touched)}">
                <label for="deliveryHoursWednesdayFrom">Mittwoch</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursWednesdayFrom"
                        name="deliveryHoursWednesdayFrom" formControlName="deliveryHoursWednesdayFrom" [ngxTimepicker]="pickerWednesdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursWednesdayTo"
                        name="deliveryHoursWednesdayTo" formControlName="deliveryHoursWednesdayTo" [ngxTimepicker]="pickerWednesdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerWednesdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerWednesdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="deliveryHoursWednesdayClosed" name="deliveryHoursWednesdayClosed" formControlName="deliveryHoursWednesdayClosed" >
                    <label class="form-check-label" for="deliveryHoursWednesdayClosed">Keine Lieferung</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('deliveryHoursWednesdayFrom').invalid && form.get('deliveryHoursWednesdayFrom').touched) || form.get('deliveryHoursWednesdayTo').invalid && form.get('deliveryHoursWednesdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('deliveryHoursThursdayFrom').invalid && form.get('deliveryHoursThursdayFrom').touched) || (form.get('deliveryHoursThursdayTo').invalid && form.get('deliveryHoursThursdayTo').touched)}">
                <label for="deliveryHoursThursdayFrom">Donnerstag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursThursdayFrom"
                        name="deliveryHoursThursdayFrom" formControlName="deliveryHoursThursdayFrom" [ngxTimepicker]="pickerThursdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursThursdayTo"
                        name="deliveryHoursThursdayTo" formControlName="deliveryHoursThursdayTo" [ngxTimepicker]="pickerThursdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerThursdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerThursdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="deliveryHoursThursdayClosed" name="deliveryHoursThursdayClosed" formControlName="deliveryHoursThursdayClosed" >
                    <label class="form-check-label" for="deliveryHoursThursdayClosed">Keine Lieferung</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('deliveryHoursThursdayFrom').invalid && form.get('deliveryHoursThursdayFrom').touched) || form.get('deliveryHoursThursdayTo').invalid && form.get('deliveryHoursThursdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('deliveryHoursFridayFrom').invalid && form.get('deliveryHoursFridayFrom').touched) || (form.get('deliveryHoursFridayTo').invalid && form.get('deliveryHoursFridayTo').touched)}">
                <label for="deliveryHoursFridayFrom">Freitag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursFridayFrom"
                        name="deliveryHoursFridayFrom" formControlName="deliveryHoursFridayFrom" [ngxTimepicker]="pickerFridayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursFridayTo"
                        name="deliveryHoursFridayTo" formControlName="deliveryHoursFridayTo" [ngxTimepicker]="pickerFridayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerFridayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerFridayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="deliveryHoursFridayClosed" name="deliveryHoursFridayClosed" formControlName="deliveryHoursFridayClosed" >
                    <label class="form-check-label" for="deliveryHoursFridayClosed">Keine Lieferung</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('deliveryHoursFridayFrom').invalid && form.get('deliveryHoursFridayFrom').touched) || form.get('deliveryHoursFridayTo').invalid && form.get('deliveryHoursFridayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('deliveryHoursSaturdayFrom').invalid && form.get('deliveryHoursSaturdayFrom').touched) || (form.get('deliveryHoursSaturdayTo').invalid && form.get('deliveryHoursSaturdayTo').touched)}">
                <label for="deliveryHoursSaturdayFrom">Samstag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursSaturdayFrom"
                        name="deliveryHoursSaturdayFrom" formControlName="deliveryHoursSaturdayFrom" [ngxTimepicker]="pickerSaturdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursSaturdayTo"
                        name="deliveryHoursSaturdayTo" formControlName="deliveryHoursSaturdayTo" [ngxTimepicker]="pickerSaturdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerSaturdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerSaturdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="deliveryHoursSaturdayClosed" name="deliveryHoursSaturdayClosed" formControlName="deliveryHoursSaturdayClosed" >
                    <label class="form-check-label" for="deliveryHoursSaturdayClosed">Keine Lieferung</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('deliveryHoursSaturdayFrom').invalid && form.get('deliveryHoursSaturdayFrom').touched) || form.get('deliveryHoursSaturdayTo').invalid && form.get('deliveryHoursSaturdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('deliveryHoursSundayFrom').invalid && form.get('deliveryHoursSundayFrom').touched) || (form.get('deliveryHoursSundayTo').invalid && form.get('deliveryHoursSundayTo').touched)}">
                <label for="deliveryHoursSundayFrom">Sonntag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursSundayFrom"
                        name="deliveryHoursSundayFrom" formControlName="deliveryHoursSundayFrom" [ngxTimepicker]="pickerSundayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="deliveryHoursSundayTo"
                        name="deliveryHoursSundayTo" formControlName="deliveryHoursSundayTo" [ngxTimepicker]="pickerSundayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerSundayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerSundayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="deliveryHoursSundayClosed" name="deliveryHoursSundayClosed" formControlName="deliveryHoursSundayClosed" >
                    <label class="form-check-label" for="deliveryHoursSundayClosed">Keine Lieferung</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('deliveryHoursSundayFrom').invalid && form.get('deliveryHoursSundayFrom').touched) || form.get('deliveryHoursSundayTo').invalid && form.get('deliveryHoursSundayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>

    </div>
 
    <div class="row">
        <button class="btn btn-danger" (click)="previousStep()">Zurück</button>
        <button class="ml-auto btn btn-success" (click)="saveAndActivateNextStep()">Speichern und weiter</button>
    </div>
</form>