
export class Address {

  constructor(
    public freeformDisplay = "",
    public street = "",
    public number = "",
    public city = "",
    public postcode = "",    
    public country = "AT",
    public floor = "",
    public entrance = "",
    public latitude : number = 0,
    public longitude : number = 0
  ){

  }  
}
