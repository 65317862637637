import { Component, OnInit } from '@angular/core';
import {  faMapMarkedAlt, faPizzaSlice, faTruck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.scss']
})
export class HowToComponent implements OnInit {

  faMapMarker = faMapMarkedAlt;
  faPizzaSlice = faPizzaSlice;
  faTruck = faTruck;


  constructor() { }

  ngOnInit(): void {
    console.log("");
  }

}
