<form [formGroup]="form">

    <h5 class="align-self-start pt-3">Öffnungszeiten</h5>
    <div>Gib deine detaillierten Öffnungszeiten als Information für deine Kunden an. Unabhängig der Öffnungszeiten kann
        der Shop jederzeit geschlossen bzw. geöffnet werden.</div>
    <div class="row pt-4">
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('shopHoursMondayFrom').invalid && form.get('shopHoursMondayFrom').touched) || (form.get('shopHoursMondayTo').invalid && form.get('shopHoursMondayTo').touched)}">
                <label for="shopHoursMondayFrom">Montag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="shopHoursMondayFrom"
                        name="shopHoursMondayFrom" formControlName="shopHoursMondayFrom" [ngxTimepicker]="pickerMondayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="shopHoursMondayTo"
                        name="shopHoursMondayTo" formControlName="shopHoursMondayTo" [ngxTimepicker]="pickerMondayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerMondayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerMondayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="shopHoursMondayClosed" name="shopHoursMondayClosed" formControlName="shopHoursMondayClosed" >
                    <label class="form-check-label" for="shopHoursMondayClosed">Geschlossen</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('shopHoursMondayFrom').invalid && form.get('shopHoursMondayFrom').touched) || form.get('shopHoursMondayTo').invalid && form.get('shopHoursMondayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('shopHoursTuesdayFrom').invalid && form.get('shopHoursTuesdayFrom').touched) || (form.get('shopHoursTuesdayTo').invalid && form.get('shopHoursTuesdayTo').touched)}">
                <label for="shopHoursTuesdayFrom">Dienstag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="shopHoursTuesdayFrom"
                        name="shopHoursTuesdayFrom" formControlName="shopHoursTuesdayFrom" [ngxTimepicker]="pickerTuesdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="shopHoursTuesdayTo"
                        name="shopHoursTuesdayTo" formControlName="shopHoursTuesdayTo" [ngxTimepicker]="pickerTuesdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerTuesdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerTuesdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="shopHoursTuesdayClosed" name="shopHoursTuesdayClosed" formControlName="shopHoursTuesdayClosed" >
                    <label class="form-check-label" for="shopHoursTuesdayClosed">Geschlossen</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('shopHoursTuesdayFrom').invalid && form.get('shopHoursTuesdayFrom').touched) || form.get('shopHoursTuesdayTo').invalid && form.get('shopHoursTuesdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('shopHoursWednesdayFrom').invalid && form.get('shopHoursWednesdayFrom').touched) || (form.get('shopHoursWednesdayTo').invalid && form.get('shopHoursWednesdayTo').touched)}">
                <label for="shopHoursWednesdayFrom">Mittwoch</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="shopHoursWednesdayFrom"
                        name="shopHoursWednesdayFrom" formControlName="shopHoursWednesdayFrom" [ngxTimepicker]="pickerWednesdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="shopHoursWednesdayTo"
                        name="shopHoursWednesdayTo" formControlName="shopHoursWednesdayTo" [ngxTimepicker]="pickerWednesdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerWednesdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerWednesdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="shopHoursWednesdayClosed" name="shopHoursWednesdayClosed" formControlName="shopHoursWednesdayClosed" >
                    <label class="form-check-label" for="shopHoursWednesdayClosed">Geschlossen</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('shopHoursWednesdayFrom').invalid && form.get('shopHoursWednesdayFrom').touched) || form.get('shopHoursWednesdayTo').invalid && form.get('shopHoursWednesdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('shopHoursThursdayFrom').invalid && form.get('shopHoursThursdayFrom').touched) || (form.get('shopHoursThursdayTo').invalid && form.get('shopHoursThursdayTo').touched)}">
                <label for="shopHoursThursdayFrom">Donnerstag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="shopHoursThursdayFrom"
                        name="shopHoursThursdayFrom" formControlName="shopHoursThursdayFrom" [ngxTimepicker]="pickerThursdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="shopHoursThursdayTo"
                        name="shopHoursThursdayTo" formControlName="shopHoursThursdayTo" [ngxTimepicker]="pickerThursdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerThursdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerThursdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="shopHoursThursdayClosed" name="shopHoursThursdayClosed" formControlName="shopHoursThursdayClosed" >
                    <label class="form-check-label" for="shopHoursThursdayClosed">Geschlossen</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('shopHoursThursdayFrom').invalid && form.get('shopHoursThursdayFrom').touched) || form.get('shopHoursThursdayTo').invalid && form.get('shopHoursThursdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('shopHoursFridayFrom').invalid && form.get('shopHoursFridayFrom').touched) || (form.get('shopHoursFridayTo').invalid && form.get('shopHoursFridayTo').touched)}">
                <label for="shopHoursFridayFrom">Freitag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="shopHoursFridayFrom"
                        name="shopHoursFridayFrom" formControlName="shopHoursFridayFrom" [ngxTimepicker]="pickerFridayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="shopHoursFridayTo"
                        name="shopHoursFridayTo" formControlName="shopHoursFridayTo" [ngxTimepicker]="pickerFridayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerFridayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerFridayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="shopHoursFridayClosed" name="shopHoursFridayClosed" formControlName="shopHoursFridayClosed" >
                    <label class="form-check-label" for="shopHoursFridayClosed">Geschlossen</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('shopHoursFridayFrom').invalid && form.get('shopHoursFridayFrom').touched) || form.get('shopHoursFridayTo').invalid && form.get('shopHoursFridayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('shopHoursSaturdayFrom').invalid && form.get('shopHoursSaturdayFrom').touched) || (form.get('shopHoursSaturdayTo').invalid && form.get('shopHoursSaturdayTo').touched)}">
                <label for="shopHoursSaturdayFrom">Samstag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="shopHoursSaturdayFrom"
                        name="shopHoursSaturdayFrom" formControlName="shopHoursSaturdayFrom" [ngxTimepicker]="pickerSaturdayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="shopHoursSaturdayTo"
                        name="shopHoursSaturdayTo" formControlName="shopHoursSaturdayTo" [ngxTimepicker]="pickerSaturdayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerSaturdayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerSaturdayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="shopHoursSaturdayClosed" name="shopHoursSaturdayClosed" formControlName="shopHoursSaturdayClosed" >
                    <label class="form-check-label" for="shopHoursSaturdayClosed">Geschlossen</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('shopHoursSaturdayFrom').invalid && form.get('shopHoursSaturdayFrom').touched) || form.get('shopHoursSaturdayTo').invalid && form.get('shopHoursSaturdayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>
        <div class="col-md-5 smallpadding-on-mobile">
            <div class="form-group"
                [ngClass]="{'has-danger': (form.get('shopHoursSundayFrom').invalid && form.get('shopHoursSundayFrom').touched) || (form.get('shopHoursSundayTo').invalid && form.get('shopHoursSundayTo').touched)}">
                <label for="shopHoursSundayFrom">Sonntag</label>
                <div class="shop-hours-row">
                    <input class="form-control form-control-lg" type="text" id="shopHoursSundayFrom"
                        name="shopHoursSundayFrom" formControlName="shopHoursSundayFrom" [ngxTimepicker]="pickerSundayFrom" [format]="24" [value]="'09:00'">
                    <b class="pl-2 pr-2">-</b>
                    <input class="form-control form-control-lg" type="text" id="shopHoursSundayTo"
                        name="shopHoursSundayTo" formControlName="shopHoursSundayTo" [ngxTimepicker]="pickerSundayTo" [format]="24" [value]="'22:00'">

                    <ngx-material-timepicker #pickerSundayFrom></ngx-material-timepicker>
                    <ngx-material-timepicker #pickerSundayTo></ngx-material-timepicker>
                </div>

                <div class="form-check pt-3">
                    <input type="checkbox" class="form-check-input shop-hours-closed" id="shopHoursSundayClosed" name="shopHoursSundayClosed" formControlName="shopHoursSundayClosed" >
                    <label class="form-check-label" for="shopHoursSundayClosed">Geschlossen</label>
                </div>

                <div class="form-control-feedback"
                    *ngIf="(form.get('shopHoursSundayFrom').invalid && form.get('shopHoursSundayFrom').touched) || form.get('shopHoursSundayTo').invalid && form.get('shopHoursSundayTo').touched">
                    Bitte gib
                    Deine Öffnungszeiten ein</div>
            </div>
        </div>

    </div>

    <div class="row">
        <button class="btn btn-danger" (click)="previousStep()">Zurück</button>
        <button class="ml-auto btn btn-success" (click)="saveAndActivateNextStep()">Speichern und weiter</button>
    </div>
</form>