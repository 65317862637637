import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MessageService {
  private messages: string[] = [];
  private toastrConfig: {} = {
    disableTimeOut: false,
    closeButton: true,
    positionClass: 'toast-bottom-right',
    progressBar: false,
    timeOut: 5000,
  };

  private toastrLongtimeoutConfig: {} = {
    disableTimeOut: false,
    closeButton: true,
    positionClass: 'toast-bottom-right',
    progressBar: false,
    timeOut: 10000,
  };

  constructor(private toastr: ToastrService) {}

  public add(message: string): void {
    this.messages.push(message);
    this.toastr.success(message, null, this.toastrConfig);
  }

  public addInfoWithLongTimeout(message: string): void {
    this.messages.push(message);
    this.toastr.info(message, null, this.toastrLongtimeoutConfig);
  }

  public addError(message: string): void {
    this.toastr.error(message, null, this.toastrConfig);
  }

  public clear(): void {
    this.messages = [];
  }
}
