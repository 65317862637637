<div class="">
    <input #searchField type="text" placeholder="Gib deine Adresse ein..."
        class="col-sm-12 form-control tomtom-search-input" [(ngModel)]="query" (click)="searchField.setSelectionRange(0, searchField.value.length); toggleSuggestions();" (dblclick)="searchField.setSelectionRange(0, searchField.value.length)">
    <div id="suggestion-container" class="suggestion-container ta-results col-sm-12 pl-0" *ngIf="showSuggestions" #suggestionsContainer>
        <div class="ta-backdrop" (click)="hideSuggestionsAndSelect()"></div>
        <div type="currentlocation"
            class="current-location ta-item list-group-item d-flex flex-row justify-content-start align-items-center flex-nowrap pl-0"
            [class.last]="!suggestions || suggestions.length == 0"
            (click)="onClickCurrentLocation()" >
            <fa-icon [icon]="faCompass"></fa-icon><span
                class="pl-3">{{ formatCurrentLocation() }}</span>
        </div>
        <div type="suggestion"
            class="suggestion ta-item list-group-item d-flex flex-row justify-content-start align-items-center flex-nowrap pl-0"
            *ngFor="let suggestion of suggestions; let i = index;" [class.highlight]="isActiveSuggestion(i)"
            [class.last]="i+1 >= suggestions.length" (click)="onClickSuggestion(suggestion, i)">
            <fa-icon [icon]="faMapMarker"></fa-icon><span class="pl-3">{{ formatSuggestion(suggestion) }}</span>
        </div>
    </div>
</div>