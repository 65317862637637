import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ShopData } from 'src/app/_models/shop-data.model';
import { ShopDataService } from '../_services/shop-data.service';
import { MessageService } from '../../core/_services/message.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-shop-hours',
  templateUrl: './shop-hours.component.html',
  styleUrls: ['./shop-hours.component.scss']
})
export class ShopHoursComponent implements OnInit {

  private CHECKBOXES = ['shopHoursMondayClosed', 'shopHoursTuesdayClosed', 'shopHoursWednesdayClosed', 'shopHoursThursdayClosed', 'shopHoursFridayClosed', 'shopHoursSaturdayClosed', 'shopHoursSundayClosed'];
  public form: FormGroup;

  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  public shopData: ShopData;

  private disableChangeEvents = false;

  constructor(
    private shopDataService: ShopDataService,
    private messageService: MessageService,

  ) {

  }


  ngOnInit(): void {
    this.form = new FormGroup({
      shopHoursMondayFrom: new FormControl('', [Validators.required]),
      shopHoursMondayTo: new FormControl('', [Validators.required]),
      shopHoursMondayClosed: new FormControl('', []),
      shopHoursTuesdayFrom: new FormControl('', [Validators.required]),
      shopHoursTuesdayTo: new FormControl('', [Validators.required]),
      shopHoursTuesdayClosed: new FormControl('', []),
      shopHoursWednesdayFrom: new FormControl('', [Validators.required]),
      shopHoursWednesdayTo: new FormControl('', [Validators.required]),
      shopHoursWednesdayClosed: new FormControl('', []),
      shopHoursThursdayFrom: new FormControl('', [Validators.required]),
      shopHoursThursdayTo: new FormControl('', [Validators.required]),
      shopHoursThursdayClosed: new FormControl('', []),
      shopHoursFridayFrom: new FormControl('', [Validators.required]),
      shopHoursFridayTo: new FormControl('', [Validators.required]),
      shopHoursFridayClosed: new FormControl('', []),
      shopHoursSaturdayFrom: new FormControl('', [Validators.required]),
      shopHoursSaturdayTo: new FormControl('', [Validators.required]),
      shopHoursSaturdayClosed: new FormControl('', []),
      shopHoursSundayFrom: new FormControl('', [Validators.required]),
      shopHoursSundayTo: new FormControl('', [Validators.required]),
      shopHoursSundayClosed: new FormControl('', []),
    }
    );

    this.addClosedListener('shopHoursMondayClosed', 'shopHoursMondayFrom', 'shopHoursMondayTo');
    this.addClosedListener('shopHoursTuesdayClosed', 'shopHoursTuesdayFrom', 'shopHoursTuesdayTo');
    this.addClosedListener('shopHoursWednesdayClosed', 'shopHoursWednesdayFrom', 'shopHoursWednesdayTo');
    this.addClosedListener('shopHoursThursdayClosed', 'shopHoursThursdayFrom', 'shopHoursThursdayTo');
    this.addClosedListener('shopHoursFridayClosed', 'shopHoursFridayFrom', 'shopHoursFridayTo');
    this.addClosedListener('shopHoursSaturdayClosed', 'shopHoursSaturdayFrom', 'shopHoursSaturdayTo');
    this.addClosedListener('shopHoursSundayClosed', 'shopHoursSundayFrom', 'shopHoursSundayTo');

    this.addChangeListenersAndAddMissingValues(
      ['shopHoursMondayFrom', 
      'shopHoursTuesdayFrom', 
      'shopHoursWednesdayFrom', 
      'shopHoursThursdayFrom', 
      'shopHoursFridayFrom', 
      'shopHoursSaturdayFrom', 
      'shopHoursSundayFrom']);

      this.addChangeListenersAndAddMissingValues(
        ['shopHoursMondayTo', 
        'shopHoursTuesdayTo', 
        'shopHoursWednesdayTo', 
        'shopHoursThursdayTo', 
        'shopHoursFridayTo', 
        'shopHoursSaturdayTo', 
        'shopHoursSundayTo']);

    this.shopDataService.currentShopData.subscribe(shopData => {
      this.shopData = shopData;
      this.form.patchValue(this.shopData);

      this.CHECKBOXES.forEach(name =>{
        var formControl = this.form.get(name);
        if(formControl.value == null || formControl.value == '' || formControl.value == false){
          formControl.setValue(false);
        }
      })
    });

  }

  async saveAndActivateNextStep() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    var newShopData = this.form.value as ShopData;
    newShopData.phone = this.shopData.phone;
    try {
      await this.shopDataService.updateShopData(newShopData);
      this.continue.emit();
    } catch (e) {
      this.messageService.addError("Shopdaten konnten nicht gespeichert werden!");
    }


  }

  previousStep() {
    this.back.emit();
  }


  addClosedListener(shopHoursClosed, shopHoursFrom, shopHoursTo) {
    this.form.get(shopHoursClosed).valueChanges.subscribe(val => {
      if (val) {
        var formControl = this.form.get(shopHoursFrom);
        if (formControl.value == null || formControl.value == '') {
          formControl.setValue('00:00');
        }
        formControl = this.form.get(shopHoursTo);
        if (formControl.value == null || formControl.value == '') {
          formControl.setValue('00:00');
        }
      }
    });
  }

  addChangeListenersAndAddMissingValues(fieldNames: string[]) {
    fieldNames.forEach(fieldName => {
      this.form.get(fieldName).valueChanges.subscribe(val => {
        if (this.disableChangeEvents || val == null || val == '' || val == '00:00') {
          return;
        }

        this.disableChangeEvents = true;

        fieldNames.forEach(otherFieldName => {
          if (otherFieldName == fieldName) {
            return;
          }

          var otherField = this.form.get(otherFieldName);
          if (otherField.value == null || otherField.value == '') {
            otherField.setValue(val)
          }
        });
        this.disableChangeEvents = false;
      })
    });
  }

}
