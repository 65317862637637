<!-- Site Footer-->
<footer class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <!-- Contact Info-->
        <section class="widget widget-light-skin">
          <h3 class="widget-title"><a class="widget-text" href="https://info.spitzaufschmankerl.at/" target="_blank">eCommerce Partner werden</a></h3>
          <p class="widget-text">Kontaktieren Sie uns unter <a class="widget-text" href="https://info.spitzaufschmankerl.at/" target="_blank">info.spitzaufschmankerl.at</a></p>
          <p class="widget-text"><a class="widget-text" href="https://info.spitzaufschmankerl.at/restaurants.html" target="_blank">Kostenloses Online Bestellsystem für Restaurants</a></p>
          <p class="widget-text"><a class="widget-text" href="https://info.spitzaufschmankerl.at/selbstvermarkter.html" target="_blank">Kostenloses Online Bestellsystem für Selbstvermarkter</a></p>
        </section>
      </div>
      <div class="col-lg-3 col-md-6">
       
      </div>
      <div class="col-lg-3 col-md-6">        
      </div>
      <div class="col-lg-3 col-md-6">
        <!-- About Us-->
        <section class="widget widget-links widget-light-skin">
          <h3 class="widget-title">Über uns</h3>
          <ul>
            <li>
              <a href="https://info.spitzaufschmankerl.at/agb.html">AGB und Datenschutz</a>
            </li>
            <li>
              <a href="https://info.spitzaufschmankerl.at/impressum.html">Impressum</a>
            </li>
            <li>
              <a (click)="onShowCookieSettings()">Cookie Einstellungen</a>
            </li>
          </ul>
        </section>
      </div>
    </div>
    <!-- Copyright-->
    <p class="footer-copyright">© Alle Rechte vorbehalten. Mit viel &nbsp;
      <i class="icon-heart text-danger"></i>
      <a href="https://www.wiffzack.com" target="_blank"> &nbsp;by Wiffzackteam UG (haftungsbeschränkt)</a>
    </p>
  </div>
</footer>
