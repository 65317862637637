import { Component, OnInit, HostListener, Input, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, debounceTime, distinctUntilChanged, tap, switchMap, concat } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Address } from 'src/app/_models/address.model';
import { faMapMarkerAlt, faCompass } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tom-tom-location-auto-complete',
  templateUrl: './tom-tom-location-auto-complete.component.html',
  styleUrls: ['./tom-tom-location-auto-complete.component.scss']
})
export class TomTomLocationAutoCompleteComponent implements OnInit, AfterViewInit {

  faMapMarker = faMapMarkerAlt;
  faCompass = faCompass;

  @Input()
  private apiKey: String = window.location.host.startsWith("www.")?"JNmfE3sCGGqidjaUGsNW7gShssaDW7ZI":"yYdGeNuJ1TfXuKIG7KBSXTn0Cg2DTow4";

  @Input()
  private debounceTime: number = 500;

  @Input()
  public query = '';

  @Output()
  private onSuggestionSelected = new EventEmitter<Address>();


  @ViewChild("searchField")
  private searchBarElement : ElementRef;

  @ViewChild("suggestionsContainer")
  private suggestionsContainerElement : ElementRef;

  private suggestionIndex = 0;
  public showSuggestions: boolean = false;
  public suggestions;

  public currentLocationSearchingInProgress: boolean = false;
  public currentLocation = null;

  private keydown$ = new Subject<KeyboardEvent>();
  private keyup$ = new Subject<KeyboardEvent>();

  constructor(private http: HttpClient) { }

  ngAfterViewInit(): void {
    this.selectWithEnter(this.keydown$);
    this.listenAndSuggest(this.keyup$);
    this.navigateWithArrows(this.keydown$);
  }

  ngOnInit(): void {
  }

  public handleHttpResultSelected(result) {
    this.query = result;
  }

  isActiveSuggestion(index: number) {
    return (index === this.suggestionIndex);
  }

  onClickSuggestion(suggestion, index: number) {
    this.query = this.formatSuggestion(suggestion);
    this.suggestionIndex = index;
    this.onSuggestionSelected.emit(this.convertSuggestionToAddress(suggestion));
    this.showSuggestions = false;
  }


  onClickCurrentLocation() {
    if (this.currentLocation == null) {
      return;
    }

    this.query = this.formatSuggestion(this.currentLocation);
    this.suggestionIndex = -1;
    this.onSuggestionSelected.emit(this.convertSuggestionToAddress(this.currentLocation));
    this.showSuggestions = false;

  }

  convertSuggestionToAddress(suggestion) {
    var lat, lon;
    if (suggestion.position) {
      lat = suggestion.position.lat;
      lon = suggestion.position.lon;
    }

    return new Address(
      suggestion.address.freeformAddress,
      suggestion.address.streetName,
      suggestion.address.number,
      suggestion.address.localName,
      suggestion.address.postalCode,
      suggestion.address.countryCode,
      null,
      null,
      lat,
      lon
    );
  }

  @HostListener('keydown', ['$event'])
  handleEsc(event: KeyboardEvent) {
    if (this.isEscapeKey(event)) {
      this.showSuggestions = false;
      event.preventDefault();
    }
    this.keydown$.next(event);
  }

  @HostListener('keyup', ['$event'])
  onkeyup(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.keyup$.next(event);
  }

  listenAndSuggest(obs: Subject<KeyboardEvent>) {
    var that = this;
    obs
      .pipe(
        filter((e) => {
          return that.validateNonCharKeyCode(e.keyCode);
        }),
        //map(() => that.searchField.nativeElement.value),
        map(() => that.query),
        debounceTime(that.debounceTime),
        concat(),
        distinctUntilChanged(),
        filter((query: string) => query.trim().length > 0),
        tap((query: string) => (that.query = query)),
      )
      .subscribe(async (query: String) => {
        that.suggestionIndex = 0;
        that.retrieveSuggestions(that, query);
        //this.assignResults(results);
        // this.updateIndex(Key.ArrowDown);
        // that.suggestions = await that.retrieveSuggestions(query);
        // console.log(that.suggestions);
      });
  }

  hideSuggestionsAndSelect() {
    if (this.query.trim() == "") {
      this.onSuggestionSelected.emit(null);
      this.toggleSuggestions();
    } else if (this.suggestions == null || this.suggestions.length == 0) {
      this.toggleSuggestions();
    } else if (this.suggestionIndex >= 0 && this.suggestionIndex < this.suggestions.length) {
      this.onClickSuggestion(this.suggestions[this.suggestionIndex], this.suggestionIndex);
    } else {
      this.showSuggestions = false;
    }
  }

  toggleSuggestions() {
    this.showSuggestions = !this.showSuggestions;

    if(this.showSuggestions){
      var searchBarRect = this.searchBarElement.nativeElement.getBoundingClientRect();
      console.log(this.searchBarElement);
      setTimeout(() => {
        document.getElementById("suggestion-container").style.width = `${searchBarRect.width}px`;  
      }, 0);
    }

    this.triggerCurrentLocationDiscovery()
  }

  triggerCurrentLocationDiscovery() {
    if (this.currentLocationSearchingInProgress) {
      return;
    }

    this.currentLocationSearchingInProgress = true;

    navigator.geolocation.getCurrentPosition((pos) => {
      this.resolveCoordinates(pos.coords.latitude, pos.coords.longitude);
    });
  }

  async resolveCoordinates(lat: number, lon: number) {
    var response = await this.http.get(`https://api.tomtom.com/search/2/reverseGeocode/crossStreet/${encodeURIComponent(lat.toString() + ',' + lon.toString())}.json?key=${this.apiKey}&language=de-DE`).toPromise();

    if (response['addresses'].length == 0) {
      response = await this.http.get(`https://api.tomtom.com/search/2/reverseGeocode/${encodeURIComponent(lat.toString() + ',' + lon.toString())}.json?key=${this.apiKey}&language=de-DE`).toPromise();
    }

    if (response['addresses'].length > 0) {
      this.currentLocation = response['addresses'][0];
    }
    console.log(this.currentLocation);
  }

  async retrieveSuggestions(that, query) {
    console.log("Retrieve suggestions");
    var response = await that.http.get(`https://api.tomtom.com/search/2/geocode/${encodeURIComponent(query)}.json?key=${that.apiKey}&countrySet=AT&typeahead=1&language=de-DE`).toPromise();

    that.suggestions = response.results;
    that.showSuggestions = true;
  }

  formatCurrentLocation() {
    if (this.currentLocation) {
      return this.formatSuggestion(this.currentLocation);
    } else {
      return "Deine Position wird ermittelt...";
    }
  }

  formatSuggestion(suggestion) {
    return suggestion.address.freeformAddress;
  }

  navigateWithArrows(elementObs: Subject<KeyboardEvent>) {
    var that = this;
    elementObs
      .pipe(
        filter((e: any) => e.keyCode === Key.ArrowDown || e.keyCode === Key.ArrowUp),
        map((e: any) => e.keyCode)
      )
      .subscribe((keyCode: number) => {
        that.updateIndex(that, keyCode);
      });
  }

  selectWithEnter(elementObs: Subject<KeyboardEvent>) {
    var that = this;
    elementObs.pipe(
      filter((e: any) => e.keyCode === Key.Enter)
    )
      .subscribe((event: KeyboardEvent) => {
        that.onClickSuggestion(that.suggestions[that.suggestionIndex], that.suggestionIndex);
      });
  }

  updateIndex(that, keyCode: number) {
    that.suggestionIndex = that.resolveNextIndex(
      that.suggestionIndex,
      keyCode === Key.ArrowDown,
      that.suggestions.length
    );
  }

  resolveNextIndex(
    currentIndex: number,
    stepUp: boolean,
    listLength = 10
  ) {
    const step = stepUp ? 1 : -1;
    const topLimit = listLength - 1;
    const bottomLimit = -1;
    const currentResultIndex = currentIndex + step;
    let resultIndex = currentResultIndex;
    if (currentResultIndex === topLimit + 1) {
      resultIndex = bottomLimit;
    }
    if (currentResultIndex === bottomLimit - 1) {
      resultIndex = topLimit;
    }
    return resultIndex;
  }

  isEscapeKey(event: KeyboardEvent) {
    return event.keyCode === Key.Escape;
  }

  validateNonCharKeyCode(keyCode: number) {
    return [
      Key.Enter,
      Key.Tab,
      Key.Shift,
      Key.ArrowLeft,
      Key.ArrowUp,
      Key.ArrowRight,
      Key.ArrowDown,
      Key.MacCommandLeft,
      Key.MacCommandRight,
      Key.MacCommandFirefox
    ].every(codeKey => codeKey !== keyCode);
  }
}


enum Key {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Escape = 27,
  ArrowLeft = 37,
  ArrowRight = 39,
  ArrowUp = 38,
  ArrowDown = 40,
  // http://unixpapa.com/js/key.html
  MacCommandLeft = 91,
  MacCommandRight = 93,
  MacCommandFirefox = 224
}