export const ERROR_GENERIC = "generic";
export const ERROR_CONNECTIONFAILED = "connection_failed";
export const ERROR_OTPTOKENVALIDATIONFAILED = "otp_tokenvalidation_failed";
export const INVALID_PHONENUMBER = "INVALID_PHONENUMBER";
export const  NOTANAUSTRIANPHONENUMER = "NOTANAUSTRIANPHONENUMER";
export const  NOTAMOBILENUMBER = "NOTAMOBILENUMBER";
export const  NOTAVALIDTOKEN = "NOTAVALIDTOKEN";

export const TOOMANYPHONELOGINS = "TOOMANYPHONELOGINS";
export const TOOMANYMAILLOGINS = "TOOMANYMAILLOGINS";
export const  TOOMANYRETRIES = "TOOMANYRETRIES";

export class ErrorWithCode{
    constructor(
        public code : String
    ){}
}