import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserConsentService } from '../_services/user-consent.service';

@Component({
  selector: 'app-user-consent',
  templateUrl: './user-consent.component.html',
  styleUrls: ['./user-consent.component.scss']
})
export class UserConsentComponent implements OnInit, OnDestroy {
  public userConsent: boolean = false;
  private consentSub: Subscription;


  constructor(private consentService : UserConsentService) { }

  ngOnInit(): void {
    this.consentSub = this.consentService.userConsentGiven.subscribe((c) => this.userConsent = c );
  }

  ngOnDestroy(): void {
    this.consentSub.unsubscribe();
  }


  onConsent(){
    this.consentService.setUserConsent(true);
  }


}
