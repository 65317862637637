import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrentUserService } from '../../shared/_services/currentuser.service';
import { AuthData } from 'src/app/_models/auth-data.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-current-account',
  templateUrl: './current-account.component.html',
  styleUrls: ['./current-account.component.scss']
})
export class CurrentAccountComponent implements OnInit, OnDestroy {

  public authData:AuthData = null;
  private authDataSubscription: Subscription;

  constructor(private currentUser : CurrentUserService) { 


  }

  ngOnInit(): void {
    this.authDataSubscription =  this.currentUser.authData.subscribe((authData) => this.authData = authData );
  }

  ngOnDestroy(): void {
    this.authDataSubscription.unsubscribe();
  }

  logout(){
    this.currentUser.authData.next(null);
  }

}
