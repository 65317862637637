import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrossDomainStorageService } from './_services/cross-domain-storage.service';
import { UserConsentService } from './_services/user-consent.service';
import { UserConsentComponent } from './user-consent/user-consent.component';
import { TomTomLocationAutoCompleteComponent } from './tom-tom-location-auto-complete/tom-tom-location-auto-complete.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountService } from './_services/account-service';
import { CurrentUserService } from './_services/currentuser.service';


@NgModule({
  declarations: [
    UserConsentComponent,
    TomTomLocationAutoCompleteComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  exports: [
    UserConsentComponent,
    TomTomLocationAutoCompleteComponent,
  ],
  providers: [
    CrossDomainStorageService,
    UserConsentService,
    AccountService,
    CurrentUserService
  ]
})
export class SharedModule { }
