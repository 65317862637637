import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable()
export class CrossDomainStorageService {

    public userConsentGiven : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    localNoConsentCache : Map<String, any> = new Map();

    ready = false;
    waitingForResponse = false;
    pendingRequests = [];

    messageOrigin: string;

    constructor() {



        window.addEventListener("message", (e) => {
            if (e.origin == this.messageOrigin) {
                var msg;
                try {
                    msg = JSON.parse(e.data);
                } catch (ex) {
                    return;
                }

                if (msg.type == 'localstorage-ready') {
                    this.ready = true;
                    this.processNextRequest();
                    return;
                }

                if (msg.type != 'localstorage') {
                    return;
                }


                if (this.waitingForResponse && this.pendingRequests.length > 0) {
                    var req = this.pendingRequests[0];
                    if (this.pendingRequests.length == 1)
                        this.pendingRequests = [];
                    else {
                        this.pendingRequests.shift();
                    }
                    if (msg.value == null || msg.value == "") {
                        req.resolve(null);
                    }
                    else {
                        try {
                            req.resolve(JSON.parse(msg.value));
                        } catch (e) {
                            req.resolve(null);
                        }
                    }
                    this.waitingForResponse = false;
                    this.processNextRequest();
                }
            }
        });

        // setTimeout(() => {
        var splittedHostname = window.location.hostname.split('.');
        var url = "data.html";
        if(splittedHostname.length >= 2){
            url = `https://${splittedHostname[splittedHostname.length - 2]}.${splittedHostname[splittedHostname.length - 1]}/data.html`;
            this.messageOrigin = `https://${splittedHostname[splittedHostname.length - 2]}.${splittedHostname[splittedHostname.length - 1]}`;
        } 

        if(environment.production == false){
            this.messageOrigin = 'http://localhost:4201';
        }
    
        var body = document.querySelector("body");        
        body.insertAdjacentHTML('beforeend', `<iframe id="if-data" style="display:none" src="${url}" >`);
    }


    processNextRequest() {
        if (this.pendingRequests.length > 0) {
            var req = this.pendingRequests[0];
            if (req.type == "get") {
                this.waitingForResponse = true;
                (document.getElementById('if-data') as HTMLIFrameElement).contentWindow.postMessage(
                    JSON.stringify({ type: 'localstorage', name: req.name }), this.messageOrigin);
            }
            else if (req.type == "set") {
                if (this.pendingRequests.length == 1)
                    this.pendingRequests = [];
                else {
                    this.pendingRequests.shift();
                }
                (document.getElementById('if-data') as HTMLIFrameElement).contentWindow.postMessage(
                    JSON.stringify({ type: 'setlocalstorage', name: req.name, value: req.value }), this.messageOrigin);

                this.processNextRequest();

            }
            else if (req.type == "remove") {
                if (this.pendingRequests.length == 1)
                    this.pendingRequests = [];
                else {
                    this.pendingRequests.shift();
                }
                (document.getElementById('if-data') as HTMLIFrameElement).contentWindow.postMessage(
                    JSON.stringify({ type: 'removelocalstorage', name: req.name }), this.messageOrigin);
                this.processNextRequest();
            }
        }

    }

    getItem(name, crossDomain = true, forceOperationWithoutConsent = false): Promise<any> {
        if(this.userConsentGiven.getValue() == false && !forceOperationWithoutConsent && this.localNoConsentCache.has(name)){
            return Promise.resolve(this.localNoConsentCache.has(name)?this.localNoConsentCache.get(name):null);
        }
        
        if (crossDomain) {
            var result = new Promise<any>((resolve, reject) => {

                this.pendingRequests.push({
                    type: "get", name: name, resolve: resolve
                });
            });
            if (this.ready && !this.waitingForResponse)
                this.processNextRequest();
            return result;
        }
        else {
            return Promise.resolve(JSON.parse(localStorage.getItem(name)));
        }
    }

    setItem(name, value, crossDomain = true, forceOperationWithoutConsent = false) {
        if(this.userConsentGiven.getValue() == false && !forceOperationWithoutConsent){
            this.localNoConsentCache.set(name, value);
        }
        else if (crossDomain) {
            this.pendingRequests.push({ type: "set", name: name, value: JSON.stringify(value) });
            if (this.ready && !this.waitingForResponse) {
                this.processNextRequest();
            }
        } else {
            localStorage.setItem(name, JSON.stringify(value));
        }
    }

    removeItem(name, crossDomain = true, forceOperationWithoutConsent = false) {
        
        if(this.userConsentGiven.getValue() == false && !forceOperationWithoutConsent){
            this.localNoConsentCache.delete(name);
        }
        else if (crossDomain) {
            this.pendingRequests.push({ type: "remove", name: name });
            if (this.ready && !this.waitingForResponse) {
                this.processNextRequest();
            }
        } else {
            localStorage.removeItem(name);
        }
    }
}