import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ShopDataService } from '../_services/shop-data.service';
import { MessageService } from '../../core/_services/message.service';
import { ShopData } from 'src/app/_models/shop-data.model';
import { createUrlValidator } from '../_utils/custom-validators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-provide-base-data',
  templateUrl: './provide-base-data.component.html',
  styleUrls: ['./provide-base-data.component.scss']
})
export class ProvideBaseDataComponent implements OnInit {
  public form: FormGroup;

  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  
  public shopData : ShopData;

  constructor(
    private shopDataService : ShopDataService,
    private messageService : MessageService,

    ) { 

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      topbarCompany: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      topbarShopHours: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      mainCompany: new FormControl('',    [Validators.required,Validators.maxLength(100)]),
      mainCompany2: new FormControl('', [ Validators.maxLength(100)]),
      customerPhone: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      urlPrefix: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z1-9]+[a-zA-Z1-9\\-]*"), Validators.maxLength(25)]),
      websiteUrl: new FormControl('', [ Validators.maxLength(100), createUrlValidator()]),
      facebookUrl: new FormControl('', [ Validators.maxLength(100), createUrlValidator()]),
      instagramUrl: new FormControl('', [ Validators.maxLength(100), createUrlValidator()]),
      mainLocation: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      mainDescription: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    
    });




    this.shopDataService.currentShopData.subscribe(shopData => {
      this.shopData = shopData;
      if(this.shopData != null){
        if(this.shopData.shopPackage == null){
          this.shopData.shopPackage = "online";
        }
        if(this.shopData.shopPickupDelivery == null){
          this.shopData.shopPickupDelivery = "pickupanddelivery";
        }
        if(this.shopData.shopDomain == null){
          this.shopData.shopDomain = "SpitzAufSchmankerl.at";
        }

        this.form.patchValue(this.shopData);
      }
    });
  }

  async saveAndActivateNextStep() {
    this.form.markAllAsTouched();
    
    if(!this.form.valid){
      return;
    }

    var newShopData = this.form.value as ShopData;
    newShopData.phone = this.shopData.phone;
    newShopData.shopPackage = this.shopData.shopPackage;
    newShopData.shopPickupDelivery = this.shopData.shopPickupDelivery;
    newShopData.shopDomain = this.shopData.shopDomain;
    try{
      await this.shopDataService.updateShopData(newShopData);
      this.continue.emit();
    } catch(e){
      this.messageService.addError("Shopdaten konnten nicht gespeichert werden!");      
    }


  }

  previousStep(){
    this.back.emit();
  }

  onPackageSelected(packageType){
    this.shopData.shopPackage = packageType;
  }

  onPickupDeliverySelected(pickupDelivery){
    this.shopData.shopPickupDelivery = pickupDelivery;
  }

  onSelectShopDomain(shopDomain){
    this.shopData.shopDomain = shopDomain;
  }



  
}
